import { invoke, asJSON, encode } from '../invoke';
import {
  MediaWriter,
  MediaWriterRequestBody,
  MediaWriterEditResponse,
} from './types';
import { LangCode } from '../lang';

/**
 * Retrieve a list of all media writers. This API doesn't provide pagination
 * at the moment because the number of media writers won't expected be too big.
 *
 * @param lang The language code of the response.
 */
export const list = async (lang: LangCode): Promise<MediaWriter[]> => {
  return asJSON(await invoke('/v1/media/writers', 'GET', { lang })).media_writers;
};

const newMediaWriter = async (lang: LangCode): Promise<{ csrfToken: string }> => { // TODO: define type
  return { csrfToken: asJSON(await invoke('/v1/media/writers/new', 'GET', { lang })).csrf_token };
};

export const create = async (lang: LangCode, body: MediaWriterRequestBody, csrfToken: string): Promise<MediaWriter> => {
  return asJSON(await invoke('/v1/media/writers', 'POST', { csrfToken }, { ...body, lang })).media_writer;
};

export const edit = async (id: string, lang: LangCode): Promise<MediaWriterEditResponse> => {
  const res = asJSON(await invoke(`${idPath(id)}/edit`, 'GET', { lang }));
  return {
    writer: res.media_writer,
    csrfToken: res.csrf_token,
  };
};

const idPath = (id: string) => `/v1/media/writers/${encode(id)}`;

export const update = async (id: string, lang: LangCode, body: MediaWriterRequestBody,
  csrfToken: string): Promise<MediaWriter> => {
  return asJSON(await invoke(idPath(id), 'PUT', { csrfToken }, { ...body, lang })).media_writer;
};

/**
 * deleteMediaWriter deletes the information of a media writer. This API deletes
 * all the information about the writer including translations and media articles
 * might also be removed.
 *
 * @param id The id of the writer to be deleted.
 * @param csrfToken A CSRF token obtained from edit API.
 */
const deleteMediaWriter = async (id: string, csrfToken: string) => {
  await invoke(idPath(id), 'DELETE', { csrfToken });
};

export {
  newMediaWriter as new,
  deleteMediaWriter as delete,
};
