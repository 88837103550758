import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DeleteWithConfirmButton, SaveButton, Toolbar } from 'react-admin';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type Props = React.ComponentProps<typeof Toolbar> & {
  confirmTitle: string,
  confirmContent: string,
};

export const EditToolbar: React.FC<Props> = props => {
  return <Toolbar {...props} classes={useStyles()}>
    <SaveButton />
    <DeleteWithConfirmButton confirmTitle={props.confirmTitle} confirmContent={props.confirmContent} />
  </Toolbar>;
};
