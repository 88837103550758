import { DataProvider } from 'ra-core';

export type DataProviderMap = {
  [resource: string]: DataProvider,
};

export const combineDataProviders = (m: DataProviderMap): DataProvider => {
  if (Object.keys(m).length === 0) throw new Error('no DataProvider');
  const map = { ...m };

  // TODO: handle errors here to convert API errors to react-admin's HttpError
  return {
    getList: (r, p) => map[r].getList(r, p),
    getOne: (r, p) => map[r].getOne(r, p),
    getMany: (r, p) => map[r].getMany(r, p),
    getManyReference: (r, p) => map[r].getManyReference(r, p),
    create: (r, p) => map[r].create(r, p),
    update: (r, p) => map[r].update(r, p),
    updateMany: (r, p) => map[r].updateMany(r, p),
    delete: (r, p) => map[r].delete(r, p),
    deleteMany: (r, p) => map[r].deleteMany(r, p),
  };
};
