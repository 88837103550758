import React from 'react';
import {
  Create as AdminCreate, SimpleForm, TextInput, required,
  SelectInput,
} from 'react-admin';
import { LangCode } from 'sake-st-api';

export type CreateProps = {
  [adminProps: string]: any,
};

export const Create: React.FC<CreateProps> = props => {
  return <AdminCreate {...props}>
    <SimpleForm>
      <TextInput source="name" fullWidth={true} validate={[required()]} />
      <TextInput source="description" fullWidth={true} />
      <SelectInput source="lang" choices={[
        { id: LangCode.ja, name: "日本語" },
        { id: LangCode.en, name: "英語" },
      ]} initialValue={LangCode.ja} validate={[required()]} />
    </SimpleForm>
  </AdminCreate>;
};
