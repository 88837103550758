import React from 'react';
import {
  List as AdminList, Datagrid, TextField, ImageField, SelectField, DateField,
  ReferenceField, Show as AdminShow, SimpleShowLayout, ReferenceArrayField,
  SingleFieldList, ChipField, EditButton, SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { langFilters, langFilterDefaults } from '../translations/common';

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: 100,
      width: 100,
      objectFit: "contain"
    }
  }
});

export type ListProps = {
  [adminProps: string]: any,
};

const filters = [
  <SelectInput source="status" choices={[
    { id: 'draft', name: 'ドラフト' },
    { id: 'review', name: 'レビュー' },
    { id: 'published', name: '公開' },
  ]} alwaysOn />,
  ...langFilters,
];

// TODO: make published_at DateField. The locale information is annoying.
// TODO: make published_at and updated_at sortable
export const List: React.FC<ListProps> = props => {
  const styles = useStyles(props);
  return <AdminList {...props} sort={{ field: 'published_at', order: 'DESC' }} filters={filters}
    filterDefaultValues={{ ...langFilterDefaults, status: 'published' }}>
    <Datagrid isRowSelectable={() => false} rowClick="expand" expand={<Show />}>
      <ImageField source="image_url" label="メイン画像" className={styles.imgContainer} sortable={false} />
      <SelectField source="status" sortable={false} choices={[
        { id: 'draft', name: 'ドラフト' },
        { id: 'review', name: 'レビュー' },
        { id: 'published', name: '公開' },
      ]} />
      <TextField source="title" sortable={false} />
      <ReferenceField source="author" label="著者" reference="writers" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="published_at" sortable={true} />
      <DateField source="updated_at" sortable={true} />
      <EditButton />
    </Datagrid>
  </AdminList>;
};

type ShowProps = {
  [adminProps: string]: any,
};

const Show: React.FC<ShowProps> = props => {
  return <AdminShow {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <ReferenceField source="author" label="著者" reference="writers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="slug" />
      <TextField source="summary" label="要約" />
      <TextField source="editor" label="編集" />
      <ImageField source="image_url" label="メイン画像" />
      <TextField source="status" />
      <ReferenceArrayField source="tags" label="タグ" reference="media-tags">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </AdminShow>;
};
