import { DataProvider } from 'react-admin';
import { invoke, MediaTags } from 'sake-st-api';

const getList: DataProvider['getList'] = async (_, params) => {
  const query = new URLSearchParams();
  query.set('from', params.filter.from);
  query.set('to', params.filter.to);
  query.set('translated', params.filter.translated ? '1' : '0');

  const res = await invoke("/v1/media/tags/translations", "GET", {
    query
  });
  if (res.kind !== 'json') {
    throw new Error("unexpected HTTP response");
  }
  return {
    data: res.json.media_tags,
    total: res.json.media_tags.length,
  };
};

const create: DataProvider['create'] = async (_, params) => {
  const { data } = params;
  const token = await MediaTags.new(data.id);
  const res = await invoke(`/v1/media/tags/${data.id}/translations`, 'POST', {
    csrfToken: token,
  }, {
    name: data.name,
    lang: data.to,
    original_lang: data.from,
  });
  if (res.kind !== 'json') {
    throw new Error("unexpected HTTP response");
  }

  return {
    data: res.json.media_tag,
  }
}

export const MediaTagTranslationsDataProvider: DataProvider = {
  getList,
  create,
} as any;
