import * as Session from './session';
import * as ErrMod from './error';

export type State = {
  // TODO: add react-admin's AdminState
  session: Session.State;
  error: ErrMod.State;
};

export type ActionTypes = Session.ActionTypes
  | ErrMod.ActionTypes;

export const reducers = {
  session: Session.reducer,
  error: ErrMod.reducer,
};

export function* rootSaga() {
  yield* Session.rootSaga();
}

declare module 'typesafe-actions' {
  interface Types {
    RootAction: ActionTypes;
  }
}
