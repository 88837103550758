import React, { useEffect } from 'react';
import { Context } from '../context';
import { LangCode } from 'sake-st-api';
import { ArticleLinkProps, Article } from './article';
import { MarkdownError } from '../error';

export type RelatedArticlesProps = {
  context: Context,
  slugs?: string[],
  lang?: LangCode,
  Link: React.ComponentType<ArticleLinkProps>,
};

/**
 * RelatedArticles processes an extension to add information of related
 * articles to the Markdown content. When context has onRelatedArticles props,
 * this component doesn't render the content and allow applications to show
 * the information in their custom format.
 *
 * Because how a link behaves depends on applications, this extension has to be
 * registered by each component with a thin wrapper that provides the Link prop.
 */
export const RelatedArticles: React.FC<RelatedArticlesProps> = props => {
  const { context, slugs, lang, Link } = props;
  useEffect(() => {
    if (context.onRelatedArticles && slugs && slugs.length > 0) {
      context.onRelatedArticles(slugs);
    }
  }, [slugs]);

  if (slugs === undefined) {
    if (!context.debug) return null;
    return <MarkdownError>"slugs" が指定されていません。</MarkdownError>;
  }
  if (slugs.length === 0) {
    return null;
  }
  if (context.onRelatedArticles) {
    return null;
  }

  const title = context.lang === LangCode.ja ? '関連記事' : 'Related Articles';
  return <>
    <h2>{title}</h2>
    {slugs.map(s => <Article key={s} context={context} slug={s} lang={lang} Link={Link} />)}
  </>;
};
