import React from 'react';
import { Edit as AdminEdit, SimpleForm, TextInput, TextField, NumberInput, DateField, required } from 'react-admin';

type Props = {
  [adminProps: string]: any;
};

// TODO: support translation

export const Edit: React.FC<Props> = props => {
  return <AdminEdit {...props} undoable={false} >
    <SimpleForm>
      <TextField source="id" />
      <TextField source="lang" />
      <TextInput source="name" fullWidth validate={[required()]} />
      <NumberInput source="sort_order" min={1} max={1000} validate={[required()]} />
      <TextInput source="image_url" fullWidth placeholder="https://images.sakestreet.com/..." />
      <TextInput source="website" fullWidth placeholder="https://www..." />
      <TextInput source="twitter" fullWidth placeholder="https://twitter.com/..." />
      <TextInput source="facebook" fullWidth placeholder="https://facebook.com/..." />
      <TextInput source="instagram" fullWidth placeholder="https://instagram.com/..." />
      <TextInput source="title" fullWidth placeholder="唎酒師, etc." />
      <TextInput source="description" multiline fullWidth validate={[required()]} />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleForm>
  </AdminEdit>;
};
