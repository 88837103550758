import { JSSProperties, CSSProperties } from './types';

// TODO: remove any. A compile error occurs in sake-st-containers probably because of the version of createUseStyles

/**
 * jss supports the code completion of CSS properties when defining JSS.
 */
export const jss = (props: JSSProperties): any => props;

export const css = (props: CSSProperties) => props;
