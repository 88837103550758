import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { LangCode } from 'sake-st-api';
import { Context } from '../context';
import { MarkdownError } from '../error';
import { register } from './registry';
import { css, jss, mediaSmartphone, enMainFontFamily } from 'sake-st-styles';
import { ProductInfo, getProductInfo } from 'sake-st-shopify';

const useStyles = createUseStyles({
  container: jss({
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    margin: [20, 0],
    borderRadius: 8,
    backgroundColor: '#fff',
    lineHeight: 1.0,
    ...mediaSmartphone({
      padding: 15,
    }),
  }),

  image: jss({
    width: 140,
    ...mediaSmartphone({
      width: 100,
      maxWidth: 'none',
    }),

    '& img': css({
      width: 140,
      height: 210,
      borderRadius: 8,
      ...mediaSmartphone({
        width: 100,
        height: 150,
        maxWidth: 'none',
      }),
    }),
  }),

  product: jss({
    width: 420,
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    ...mediaSmartphone({
      width: 180,
    }),
  }),

  info: {
    width: '100%',

    '& .name': {
      margin: 0,
      fontFamily: enMainFontFamily,
      fontSize: 16,
    },

    '& .tags': {
      margin: [20, 0, 0, 0],
      padding: 0,
      ...mediaSmartphone({
        margin: [6, 0, 0, 0],
      }),

      '& li.tag': {
        margin: [4, 4, 0, 0],
        padding: 5,
        display: 'inline-block',
        fontSize: 14,
        borderRadius: 4,
        backgroundColor: '#f5f2ed',
        ...mediaSmartphone({
          margin: [2, 0, 0, 0],
          fontSize: 12,
        }),
      },
    },

    '& .description': {
      margin: [20, 0, 0, 0],
      marginBottom: 0,
      fontSize: 16,
      lineHeight: 1.6,
      ...mediaSmartphone({
        margin: [6, 0, 0, 0],
        fontSize: 14,
      }),
    },
  },

  details: jss({ // Note: product-info-2
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    ...mediaSmartphone({
      display: 'block',
    }),

    '& .skuList': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      ...mediaSmartphone({
        marginTop: 6,
      }),

      '& li.item': {
        margin: 0,
        padding: 0,
        fontSize: 16,
        ...mediaSmartphone({
          fontSize: 14,
        }),
        '&:nth-of-type(n+2)': {
          marginTop: 8,
          ...mediaSmartphone({
            marginTop: 2,
          }),
        },
        '&.soldOut': {
          textDecoration: 'line-through',
        },
        '&.soldOut:after': {
          content: "url('https://cdn.shopify.com/s/files/1/0295/5349/2103/files/product-sold-out.png?v=1597563877')",
          marginLeft: 5,
        },
      },
    },
    '& .button': {
      padding: 14,
      width: 140,
      textAlign: 'center',
      backgroundColor: '#98001b',
      borderRadius: '24px',
      ...mediaSmartphone({
        padding: 10,
        width: 120,
        margin: [16, 'auto', 0, 'auto'],
        fontSize: 14,
      }),
      '& a': {
        color: '#fff',
      },
    },
  }),
});

export type ProductProps = {
  context: Context,
  id: string,
  lang?: LangCode,
};

/**
 *  Show information of a specified product on Shopify.
 */
export const Product: React.FC<ProductProps> = props => {
  // TODO: lazy load
  const { id } = props;
  // const { context } = props;
  // const lang = props.lang ? props.lang : context.lang;
  const [error, setError] = useState<string>();
  const [content, setContent] = useState<ProductInfo>();
  const styles = useStyles();

  useEffect(() => {
    // FIXME: I wasn't sure how to avoid unmounting and remounting that happen
    // multiple times to this component when it was used via Products extension.
    // This implementation temporarily debounces the request to Shopify to avoid
    // invoking the API too many times.
    //
    // Figure out the source of unnecessary rerendering and mounting.

    let canceled = false;
    setTimeout(() => {
      if (canceled) return;

      getProductInfo([id])
        .then(info => !canceled && setContent(info[0]))
        .catch(err => !canceled && setError(`Request failed: ${err}`));
    }, 1000);
    return () => {
      canceled = true;
    };
  }, [id]);

  if (error) {
    if (!props.context.debug) return null;
    return <MarkdownError>{error}</MarkdownError>;
  }

  if (!content) {
    return null; // TODO: spinner
  }

  return <div className={styles.container}>
    <div className={styles.image}>
      <img src={content.image.url} alt={content.image.alt} />
    </div>
    <div className={styles.product}>
      <div className={styles.info}>
        <p className="name">{content.name}</p>
        <ul className="tags">
          {content.tags.map(t => <li key={t} className="tag">{t}</li>)}
        </ul>
        <p className="description">
          {content.description.length <= 70 ? content.description : (content.description.substr(0, 70) + "…")}
        </p>
      </div>
      <div className={styles.details}>
        <ul className="skuList">
          {content.variants.map(v =>
            <li key={v.id} className={'item' + (v.available ? '' : ' soldOut')}>
              {v.name} {v.price}円
            </li>)}
        </ul>
        <div className="button">
          <a href={content.url} target="_blank" rel="noopener">購入はこちら</a>
        </div>
      </div>
    </div>
  </div>;
};

register('product', Product);
