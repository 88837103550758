import { DataProvider } from 'ra-core';
import { MediaImages } from 'sake-st-api';

const getList: DataProvider['getList'] = async (_, params) => {
  const res = await MediaImages.list({
    paging: {
      page: params.pagination.page - 1,
      size: params.pagination.perPage,
    },
  });
  return {
    data: res.media_images as any,
    total: res.paging.total,
  };
};

const getOne: DataProvider['getOne'] = async (_, params) => {
  const res = await MediaImages.edit(params.id.toString());
  return {
    data: res.media_image as any,
  };
};

const getMany: DataProvider['getMany'] = async (_, params) => {
  const res = await Promise.all(params.ids.map(id => MediaImages.edit(id.toString())));
  return {
    data: res.map(r => r.media_image) as any,
  };
};

const getManyReference: DataProvider['getManyReference'] = async () => {
  // TODO: add ?author filter
  throw new Error("media image has no reference");
};

const create: DataProvider['create'] = async (_, params) => {
  let result: ArrayBuffer = params.data.image;
  if (!(result instanceof ArrayBuffer)) {
    result = await new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(params.data.image.rawFile);
    });
  }

  const a = new Uint8Array(result);
  let image_ext = '';
  const header = ((a[0] << 24) | (a[1] << 16) | (a[2] << 8) | a[3]) >>> 0;
  switch (header) {
    case 0x89504e47:
      image_ext = '.png';
      break;
    case 0x47494638:
      image_ext = '.gif';
      break;
    case 0xffd8ffe0:
    case 0xffd8ffe1:
    case 0xffd8ffe2:
    case 0xffd8ffe3:
    case 0xffd8ffe8:
      image_ext = '.jpg';
      break;
    default:
      throw new Error('アップロードできるファイルはjpeg, png, gifのみです');
  }

  let buf = '';
  const len = a.length;
  for (let i = 0; i < len; i++) {
    buf += String.fromCharCode(a[i]);
  }

  const image = btoa(buf);
  const token = await MediaImages.new();
  const res = await MediaImages.create({ ...params.data, image, image_ext }, token);
  return {
    data: res.media_image as any,
  };
};

const update: DataProvider['update'] = async (_, params) => {
  const id = params.id.toString();
  const token = await MediaImages.edit(id);
  const res = await MediaImages.update(id, params.data, token.csrf_token);
  return {
    data: res.media_image as any,
  };
};

const updateMany: DataProvider['updateMany'] = async () => {
  throw new Error("update many isn't supported");
};

const deleteReq: DataProvider['delete'] = async (_, params) => {
  const id = params.id.toString();
  const token = await MediaImages.edit(id);
  await MediaImages.delete(id, token.csrf_token);
  return {
    data: { id } as any,
  };
};

const deleteMany: DataProvider['deleteMany'] = async (_, params) => {
  const tokens = await Promise.all(params.ids.map(id => {
    return MediaImages.edit(id.toString());
  }));
  await Promise.all(tokens.map(t => MediaImages.delete(t.media_image.id, t.csrf_token)));
  return {
    data: params.ids,
  };
};

export const MediaImagesDataProvider: DataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  updateMany,
  delete: deleteReq,
  deleteMany,
};