import React from 'react';
import { createUseStyles } from 'react-jss';
import { register } from './registry';

const useStyles = createUseStyles({
  balloon: {
    margin: [18, 0],
    width: '100%',
    overflow: 'hidden',
  },

  icon: {
    float: 'left',
    marginRight: -90,
    width: 80,
  },

  chat: {
    width: '100%',
  },

  content: {
    display: 'inline-block',
    position: 'relative',
    margin: [5, 0, 0, 105],
    padding: [17, 13],
    borderRadius: 12,
    background: '#FFFFFF',

    '&:after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: 18,
      left: -24,
      border: [12, 'solid', 'transparent'],
      borderRight: [12, 'solid', '#FFFFFF'],
    },

    '& p': {
      margin: 0,
      padding: 0,
    }
  },
});

type Props = {
  img: string,
  name: string,
  content: React.ReactNode,
};

export const Chat: React.FC<Props> = props => {
  const { img, name, content } = props;
  const styles = useStyles();
  return <div className={styles.balloon}>
    <div className={styles.icon}>
      <img src={img} alt={name} />
    </div>
    <div className={styles.chat}>
      <div className={styles.content}>
        {content}
      </div>
    </div>
  </div>;
};

register('chat', Chat);
