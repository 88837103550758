import {
  ListResult,
  RequestBody,
  EditResponse,
  UploadResponse,
  ListOptions,
} from './types';
import { invoke, asJSON, encode } from '../invoke';

export const list = async (opt?: ListOptions): Promise<ListResult> => {
  const query = new URLSearchParams();
  if (opt?.paging?.page) query.append('page', opt.paging.page.toString());
  if (opt?.paging?.size) query.append('size', opt.paging.size.toString());
  return asJSON(await invoke('/v1/media/images', 'GET', { query }));
};

/**
 * Obtains a CSRF token to create a new media image resource.
 */
const New = async (): Promise<string> => {
  return asJSON(await invoke('/v1/media/images/new')).csrf_token;
};

export const create = async (body: RequestBody, csrfToken: string): Promise<UploadResponse> => {
  return asJSON(await invoke('/v1/media/images', 'POST', { csrfToken }, body));
};

export const edit = async (id: string): Promise<EditResponse> => {
  return asJSON(await invoke(`/v1/media/images/${encode(id)}/edit`));
};

export const update = async (id: string, body: RequestBody, csrfToken: string): Promise<UploadResponse> => {
  return asJSON(await invoke(`/v1/media/images/${encode(id)}`, 'PUT', { csrfToken }, body));
};

const Delete = async (id: string, csrfToken: string): Promise<void> => {
  await invoke(`/v1/media/images/${encode(id)}`, 'DELETE', { csrfToken });
};

export {
  New as new,
  Delete as delete,
};
