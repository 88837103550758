import React from 'react';
import { Context } from '../context';
import { MarkdownError } from '../error';
import { register } from './registry';

export type ErrorProps = {
  context: Context
  msg: string,
  json: string,
  error: string,
};

export const Error: React.FC<ErrorProps> = props => {
  if (!props.context.debug) return null;
  return <MarkdownError>
    {props.msg}: {props.error}

    <pre>{props.json}</pre>
  </MarkdownError>;
};

register('error', Error);
