import { CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { asJSON, invoke } from 'sake-st-api';

interface Order {
  id: string;
  created_at: string;
  payment_status: string;
  name: string;
  zip: string;
  address: string;
  phone: string;
  items: OrderItem[];

  more_items: boolean;
  shipping_method: string;
  note: string;
}

// TODO: fix these to lower case names
interface OrderItem {
  name: string;
  quantity: number;
  price: string;
}

const useStyles = createUseStyles({
  container: {
    margin: 10,
  },
  list: {
    border: [2, 'solid', 'black'],
    borderCollapse: 'collapse',
    width: '100%',
    '& th, tr, td': {
      border: [2, 'solid', 'black'],
    },
  },
});

export const PickUpOrders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>();
  const styles = useStyles();

  useEffect(() => {
    invoke('/v1/store/orders')
      .then(res => asJSON(res))
      .then(res => {
        setOrders(res.store_pick_up);
      });
  }, []);
  if (orders === undefined) {
    return <CircularProgress />;
  }

  return <div className={styles.container}>
    <table className={styles.list}>
      <thead>
        <tr>
          <th>ID</th>
          <th>名前</th>
          <th>電話番号</th>
          <th>決済状況</th>
          <th>商品</th>
          <th>備考</th>
          <th>注文日時</th>
        </tr>
      </thead>
      <tbody>
        {orders.map(o => <tr key={o.id}>
          <td>{o.id}</td>
          <td>{o.name}</td>
          <td>{o.phone}</td>
          <td>{o.payment_status}</td>
          <td>
            <ul>
              {o.items.map(i => <li key={i.name}>{i.name} ({i.price.split('.')[0]}円) - {i.quantity}本</li>)}
              {o.more_items && '注文数が多いので管理画面で残りの商品を確認してください。'}
            </ul>
          </td>
          <td>{o.note}</td>
          <td>{o.created_at.substr(0, 10)}</td>
        </tr>)}
      </tbody>
    </table>
  </div>;
};
