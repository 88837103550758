import { invoke, asJSON } from '../invoke';
import { User } from '../users';

export const login = async (email: string, password: string): Promise<User> => {
  return asJSON(await invoke('/v1/login', 'POST', undefined, { email, password })).user;
};

export const logout = async () => {
  await invoke('/v1/logout', 'DELETE');
};

export const me = async (): Promise<User> => {
  return asJSON(await invoke('/v1/me')).user;
};
