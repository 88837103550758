import React, { useState, useEffect } from 'react';
import { MediaAPI, LangCode, InvokeError } from 'sake-st-api';
import { Context } from '../context';
import { createUseStyles } from 'react-jss';
import { mediaSmartphone, css, jss } from 'sake-st-styles';

// TODO: this style is temporary
const useStyles = createUseStyles({
  link: jss({
    marginBottom: 24,
    position: 'relative',
    zIndex: 1,
    border: 'none',
    padding: 20,
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: 8,

    ...mediaSmartphone({
      width: '100%',
      height: '100%',
      display: 'block',
      padding: 16,
    }),

    '& a': jss({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      textIndent: -999,
      zIndex: 2,

      '&:hover': {
        filter: 'alpha(opacity=70)',/* IE 6,7*/
        '-ms-filter': 'alpha(opacity=70)',/* IE 8,9 */
        '-moz-opacity': 0.7,/* FF , Netscape */
        '-khtml-opacity': 0.7,/* Safari 1.x */
        opacity: 0.7,
        zoom: 1,/*IE*/
      },
    }),
  }),

  thumbnail: jss({
    width: 180,
    height: 120,
    '& img': css({
      width: 180,
      height: 120,
      borderRadius: 8,

      ...mediaSmartphone({
        width: '100%',
        height: 'auto',
      }),
    }),

    ...mediaSmartphone({
      width: '100%',
      height: '100%',
    }),
  }),

  content: jss({
    padding: [0, 0, 0, 20],

    ...mediaSmartphone({
      padding: [0, 16],
    }),

    '& .title': css({
      margin: 0,
      fontWeight: 600,
      lineHeight: 1.4,
    }),

    '& .summary': css({
      margin: [16, 0, 0, 0],
      fontSize: 14,
      lineHeight: 1.6,
    }),
  }),
});

export type ArticleLinkProps = {
  slug: string,
  lang: LangCode,
};

export type ArticleProps = {
  context: Context,
  slug: string,
  lang?: LangCode,
  Link: React.ComponentType<ArticleLinkProps>,
};

type Content = {
  title: string,
  summary: string,
  imageUrl: string,
  slug: string,
  lang: LangCode,
};

/**
 * Article creates a link to an article with embedded content information.
 * Because how a link behaves depends on applications, this extension has to be
 * registered by each component with a thin wrapper that provides the Link prop.
 */
export const Article: React.FC<ArticleProps> = props => {
  const { context, slug, Link } = props;
  const lang = props.lang ? props.lang : context.lang;

  const styles = useStyles();
  const [content, setContent] = useState<Content>();
  const [err, setErr] = useState<InvokeError>();

  useEffect(() => {
    // TODO: support multilingual
    // TODO: don't retrieve content
    MediaAPI.show(slug, lang, { withContent: false }).then(res => {
      const { media } = res;
      setContent({
        title: media.title,
        summary: media.summary,
        imageUrl: media.image_url,
        slug: media.slug,
        lang: media.lang,
      });
    }).catch(setErr);
  }, [slug, lang]);

  if (err) {
    return <div>
      <Link slug={slug} lang={lang}>{slug}</Link>
      {context.debug && <span style={{ fontSize: 36, fontWeight: 'bold', border: '2px solid' }}>{JSON.stringify(err)}</span>}
    </div>;
  }

  if (!content) {
    // TODO: spinner
    return <div></div>;
  }
  return <div className={styles.link}>
    <div className={styles.thumbnail}>
      <img src={content.imageUrl} alt={content.title} />
    </div>
    <div className={styles.content}>
      <p className="title">{content.title}</p>
      <p className="summary">{content.summary.length <= 50 ? content.summary : `${content.summary.substr(0, 50)}...`}</p>
    </div>
    <Link slug={slug} lang={lang} />
  </div>;
};
