import { DataProvider } from 'ra-core';
import { Media, MediaAPI, LangCode } from 'sake-st-api';

/**
 * This is the type of Media that is internally used to follow the convention
 * of react-admin.
 */
export type InternalMedia =
  Omit<MediaAPI.MediaRequestBody, 'tags' | 'content'> & {
    id: string,
    lang: string,
    status: string,
    editor: string,
    raw_content: string,
    tags: string[],
    created_at: string,
    updated_at: string,
  };

const modifyMedia = (m: Media): InternalMedia => {
  // Because slug is used as an ID and react-admin can only handle 'id' as the
  // ID field, the value of Media.id is changed here. The original Media.id is
  // not used at media-admin and not necessary.
  m.id = `${m.lang}:${m.slug}`;
  return {
    id: `${m.lang}:${m.slug}`,
    lang: m.lang,
    status: m.status,
    tags: m.tags ? m.tags.map(t => `${m.lang}:${t.id}`) : [],

    title: m.title,
    author: `${m.lang}:${m.author.id}`,
    editor: m.editor.account_id,
    slug: m.slug,
    summary: m.summary,
    image_url: m.image_url,
    raw_content: m.raw_content || '',
    published_at: m.published_at.substr(0, '2006-01-02'.length),
    created_at: m.created_at,
    updated_at: m.updated_at,
  };
};

const toRequestBody = (m: InternalMedia): MediaAPI.MediaRequestBody => {
  return {
    title: m.title,
    author: splitId(m.author).id,
    slug: m.slug,
    summary: m.summary,
    image_url: m.image_url,
    content: m.raw_content,
    published_at: m.published_at + 'T12:00:00Z',
    tags: m.tags.map(t => splitId(t).id),
  };
};

const splitId = (id: string | number) => {
  const ids = id.toString().split(':');
  return {
    id: ids[1],
    lang: ids[0] as LangCode,
  };
};

const getList: DataProvider['getList'] = async (_, params) => {
  const q = new URLSearchParams();
  q.append('page', (params.pagination.page - 1).toString());
  q.append('size', params.pagination.perPage.toString());
  if ('status' in params.filter) q.append('status', params.filter.status);
  q.append('sort', `${params.sort.field}:${params.sort.order === 'ASC' ? 'asc' : 'desc'}`);

  // TODO: add filters
  const res = await MediaAPI.list(params.filter.lang, {
    kind: 'query',
    query: q,
  });
  return {
    data: res.media.map(modifyMedia) as any,
    total: res.paging.total,
  };
};

const getOne: DataProvider['getOne'] = async (_, params) => {
  const { id, lang } = splitId(params.id);
  const res = await MediaAPI.edit(id, lang);
  return {
    data: modifyMedia(res.media) as any,
  };
};

const getMany: DataProvider['getMany'] = async (_, params) => {
  // TODO: provide endpoint to do this efficiently
  const res = await Promise.all(params.ids.map(jointId => {
    const { id, lang } = splitId(jointId);
    return MediaAPI.edit(id, lang);
  }));
  return {
    data: res.map(r => modifyMedia(r.media)) as any,
  };
};

const getManyReference: DataProvider['getManyReference'] = async () => {
  // TODO: add ?author filter
  throw new Error("order has no reference");
};

const create: DataProvider['create'] = async (_, params) => {
  const token = await MediaAPI.new(params.data.lang);
  const media = await MediaAPI.create(params.data.lang, toRequestBody(params.data), token.csrfToken);
  return {
    data: modifyMedia(media) as any,
  };
};

const update: DataProvider['update'] = async (_, params) => {
  const { id, lang } = splitId(params.id);
  const token = await MediaAPI.edit(id, lang);
  const media = await MediaAPI.update(id, lang, toRequestBody(params.data), token.csrfToken);
  if (params.data.status !== params.previousData.status) {
    await MediaAPI.updateStatus(id, lang, params.data.status, token.csrfToken);
  }
  return {
    data: media as any,
  };
};

const updateMany: DataProvider['updateMany'] = async () => {
  throw new Error("update many isn't supported");
};

const deleteReq: DataProvider['delete'] = async (_, params) => {
  const { id, lang } = splitId(params.id);
  const token = await MediaAPI.edit(id, lang);
  await MediaAPI.delete(id, lang, token.csrfToken);
  return {
    data: { id } as any,
  };
};

const deleteMany: DataProvider['deleteMany'] = async (_, params) => {
  const tokens = await Promise.all(params.ids.map(jointId => {
    const { id, lang } = splitId(jointId);
    return MediaAPI.edit(id, lang);
  }));
  await Promise.all(tokens.map(t => MediaAPI.delete(t.media.id, t.media.lang, t.csrfToken)));
  return {
    data: params.ids as any,
  };
};

export const MediaDataProvider: DataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  updateMany,
  delete: deleteReq,
  deleteMany,
};