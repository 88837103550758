import React from 'react';
import { ButtonProps, useListContext, useRecordContext, Button } from 'react-admin';
import ContentCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';

interface Props extends ButtonProps {
  idField?: string;
}

/**
 * TranslationButton is a component that can only be used in List. It assumes
 * `from`, `to`, and `translated` query filters are provided.
 */
export const TranslationButton: React.FC<Props> = ({
  icon = <ContentCreate />,
  idField = 'id',
  ...rest
}) => {
  const { resource, filterValues: { from, to, translated } } = useListContext();
  const record = useRecordContext();
  return <Button {...rest} component={Link} disabled={translated}
    to={`/${resource}/create?id=${record[idField]}&from=${from}&to=${to}`}>
    <span>{icon}翻訳</span>
  </Button >;
};
