import { createReducer } from 'typesafe-actions';
import * as T from './types';
import * as A from './actions';

export const initialState: T.State = {
  errors: {},
  maxHistory: 10,
};

export const reducer = createReducer(initialState)
  .handleAction(A.put, (state, action) => {
    const s = { ...state };
    const scope = action.payload.scope;
    let a = state.errors[scope];
    if (!a) a = [];
    if (a.length >= s.maxHistory) {
      a = a.slice(a.length - s.maxHistory + 1);
    }
    s.errors[scope] = [...a, action.payload.err];
    return s;
  })
  .handleAction(A.clear, (state, action) => {
    let s = { ...state };
    s.errors[action.payload] = [];
    return s;
  })
  .handleAction(A.clearAll, state => ({
    ...state,
    errors: {},
  }));
