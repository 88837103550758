import {
  ListResult, Record, RequestBody, EditResponse,
} from './types';
import { asJSON, invoke, encode } from '../invoke';
import { LangCode } from '../lang';

export const list = async (lang: LangCode): Promise<ListResult> => {
  return asJSON(await invoke('/v1/media/tags', 'GET', { lang }));
};

const New = async (lang: LangCode): Promise<string> => {
  return asJSON(await invoke('/v1/media/tags/new', 'GET', { lang })).csrf_token;
};

export const create = async (body: RequestBody, csrfToken: string): Promise<Record> => {
  return asJSON(await invoke('/v1/media/tags', 'POST', { csrfToken }, body)).media_tag;
};

export const edit = async (id: string, lang: LangCode): Promise<EditResponse> => {
  return asJSON(await invoke(`/v1/media/tags/${encode(id)}/edit`, 'GET', { lang }));
};

export const update = async (id: string, body: RequestBody, csrfToken: string): Promise<Record> => {
  return asJSON(await invoke(`/v1/media/tags/${encode(id)}`, 'PUT', { csrfToken }, body)).media_tag;
};

const Delete = async (id: string, csrfToken: string): Promise<void> => {
  await invoke(`/v1/media/tags/${encode(id)}`, 'DELETE', { csrfToken });
};

export {
  New as new,
  Delete as delete,
};
