import React from 'react';
import {
  Create as AdminCreate, SimpleForm, TextInput,
  ReferenceInput, SelectInput, ReferenceArrayInput,
  SelectArrayInput, required, FormDataConsumer, DateInput,
  useQuery, Error, Loading,
} from 'react-admin';
import { LangCode } from 'sake-st-api';
import { DebounceImage } from '../../common/debounce-image';
import { Editor } from '../../media/editor';
import { useLocation } from 'react-router';

export type CreateProps = {
  [adminProps: string]: any,
};

const requiredValidations = [required()];

export const Create: React.FC<CreateProps> = props => {
  const q = new URLSearchParams(useLocation().search);
  const from = q.get('from');
  const to = q.get('to');
  const slug = q.get('id');

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "media",
    payload: { id: `${from}:${slug}` },
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <div>執筆者情報を取得できませんでした</div>;

  return <AdminCreate {...props}>
    <SimpleForm warnWhenUnsavedChanges sanitizeEmptyValues={false} initialValues={{
      ...data,
      slug,
      from,
      to,
    }}>
      <SelectInput source="from" disabled={true} choices={[
        { id: LangCode.ja, name: "日本語" },
        { id: LangCode.en, name: "英語" },
      ]} validate={[required()]} />
      <SelectInput source="to" disabled={true} choices={[
        { id: LangCode.ja, name: "日本語" },
        { id: LangCode.en, name: "英語" },
      ]} validate={[required()]} />

      <TextInput source="id" disabled={true} validate={[required()]} />
      <TextInput source="title" fullWidth={true} validate={requiredValidations} />
      <ReferenceInput disabled source="author" reference="writers" validate={requiredValidations}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="slug" disabled fullWidth={true} validate={requiredValidations} />
      <TextInput source="summary" fullWidth={true} validate={requiredValidations} />
      <DateInput source="published_at" validate={requiredValidations} />
      <TextInput source="image_url" fullWidth={true} validate={requiredValidations} />
      <FormDataConsumer>
        {
          ({ formData }) => <DebounceImage src={formData.image_url} />
        }
      </FormDataConsumer>
      <ReferenceArrayInput disabled source="tags" label="タグ" reference="media-tags">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <FormDataConsumer>
        {
          ({ formData }) => <Editor source="raw_content" mediaTitle={formData.title} lang={formData.lang as LangCode} validate={requiredValidations} />
        }
      </FormDataConsumer>
    </SimpleForm>
  </AdminCreate>;
};
