import { createAction } from 'typesafe-actions';
import { User } from 'sake-st-api';
import * as T from './types';

export const FETCH_ME = 'FETCH_ME';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAILURE = 'FETCH_ME_FAILURE';
export const LOGIN = 'LOGIN';
export const BEGIN_LOGIN = 'BEGIN_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const fetchMe = createAction(FETCH_ME)();
export const fetchMeSuccess = createAction(FETCH_ME_SUCCESS)<User>();
export const fetchMeFailure = createAction(FETCH_ME_FAILURE)();

export const login = createAction(LOGIN)<T.LoginInformation>();
export const beginLogin = createAction(BEGIN_LOGIN)();
export const loginSuccess = createAction(LOGIN_SUCCESS)<User>();
export const loginFailure = createAction(LOGIN_FAILURE)();

export const logout = createAction(LOGOUT)<T.SessionCallbacks>();
export const logoutSuccess = createAction(LOGOUT_SUCCESS)();
