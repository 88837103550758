import { DataProvider } from 'react-admin';
import { invoke, MediaWriters } from 'sake-st-api';

const getList: DataProvider['getList'] = async (_, params) => {
  const query = new URLSearchParams();
  query.set('from', params.filter.from);
  query.set('to', params.filter.to);
  query.set('translated', params.filter.translated ? '1' : '0');

  const res = await invoke("/v1/media/writers/translations", "GET", {
    query
  });
  if (res.kind !== 'json') {
    throw new Error("unexpected HTTP response");
  }
  return {
    data: res.json.media_writers,
    total: res.json.media_writers.length,
  };
};

const create: DataProvider['create'] = async (_, params) => {
  const { data } = params;
  const token = await MediaWriters.new(data.id);
  const res = await invoke(`/v1/media/writers/${data.id}/translations`, 'POST', {
    csrfToken: token.csrfToken,
  }, {
    sort_order: data.sort_order,
    name: data.name,
    title: data.title,
    description: data.description,
    image_url: data.image_url,
    website: data.website,
    twitter: data.twitter,
    facebook: data.facebook,
    instagram: data.instagram,

    lang: data.to,
    original_lang: data.from,
  });
  if (res.kind !== 'json') {
    throw new Error("unexpected HTTP response");
  }

  return {
    data: res.json.media_writer,
  }
}

export const MediaWriterTranslationsDataProvider: DataProvider = {
  getList,
  create,
} as any;
