import { MediaDataProvider } from './media';
import { MediaWriterDataProvider } from './writers';
import { MediaImagesDataProvider } from './media-images';
import { MediaTagsDataProvider } from './media-tags';
import { combineDataProviders } from './map';
import { MediaTagTranslationsDataProvider } from './media-tag-translations';
import { MediaWriterTranslationsDataProvider } from './media-writer-translations';
import { MediaTranslationsDataProvider } from './media-translations';

export const dataProvider = combineDataProviders({
  'media': MediaDataProvider,
  'writers': MediaWriterDataProvider,
  'media-images': MediaImagesDataProvider,
  'media-tags': MediaTagsDataProvider,

  'media-translations': MediaTranslationsDataProvider,
  'media-writer-translations': MediaWriterTranslationsDataProvider,
  'media-tag-translations': MediaTagTranslationsDataProvider,
});
