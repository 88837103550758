import { User } from '../users';
import { MediaWriter } from '../media-writers';
import { Record as Tag } from '../media-tags';
import { Node } from 'sake-st-markdown-types';
import { LangCode } from 'lang';

export const MediaStatus = {
  Draft: 'draft',
  Review: 'review',
  Published: 'published',
} as const;
export type MediaStatus = typeof MediaStatus[keyof typeof MediaStatus];

export type Media = {
  id: string,
  lang: LangCode,
  status: MediaStatus,
  title: string,
  slug: string,
  summary: string,

  // TODO: author and translator don't have admin and media_writer flags in the
  // response from media.
  author: MediaWriter,
  editor: User,

  translator: MediaWriter,
  translation_editor: User,

  image_url: string,
  content?: Node,
  raw_content?: string,
  tags?: Tag[],

  published_at: string,
  created_at: string,
  updated_at: string,
};

// TODO: define new kind to accept better arguments
export type MediaListArg = {
  kind: 'query',
  query: URLSearchParams,
};

export type MediaListResult = {
  media: Media[],
  paging: { // TODO: extract as paging
    page: number,
    size: number,
    total: number,
    base_offset?: number,
    next?: string,
    prev?: string,
    // TODO: sort might not be necessary
  },
  trending: Media[],
  popular: Media[],
  pickup: Media[],
  params?: {
    tag?: Tag,
    author?: MediaWriter,
  },
};

export type MediaSitemapEntry = {
  slug: string,
  updated_at: string,
};

export type MediaSitemapResult = {
  media: MediaSitemapEntry[],
};

export type MediaShowOptions = Partial<{
  nav: boolean,
  withContent: boolean,
  withRawContent: boolean,
}>;

export type MediaShowLink = {
  slug: string,
  title: string,
};

export type MediaShowResult = {
  media: Media,
  next: MediaShowLink,
  prev: MediaShowLink,
  latest: Media[],
  trending: Media[],
  popular: Media[],
  pickup: Media[],
};

export type MediaListArgTypes = MediaListArg;

export type MediaEditResponse = {
  media: Media,
  csrfToken: string,
};

// TODO: support translator, editor/translation_editor
export type MediaRequestBody = {
  title: string,
  author: string,
  slug: string,
  summary: string,
  image_url: string,
  content: string,
  published_at: string,
  tags?: string[] | Tag[],
};
