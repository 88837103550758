import React from 'react';
import { BulkDeleteButton } from 'react-admin';

type Props = React.ComponentProps<typeof BulkDeleteButton>;

export const BulkDeleteToolbar: React.FC<Props> = props => {
  return <>
    <BulkDeleteButton {...props} undoable={false} />
  </>;
};
