import { createReducer } from 'typesafe-actions';
import { User } from 'sake-st-api';
import * as T from './types';
import * as A from './actions';

export const initialUser: User = {
  id: "",
  account_id: "",
  admin: false,
  media_writer: false,
  created_at: "",
  updated_at: "",
};

export const initialState: T.State = {
  ...initialUser,
  fetched: false,
  loggedIn: false,
  processingLogIn: false,
};

export const reducer = createReducer(initialState)
  .handleAction(A.fetchMeSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    fetched: true,
    loggedIn: true,
  }))

  .handleAction(A.fetchMeFailure, state => ({
    ...state,
    fetched: true,
    loggedIn: false,
  }))

  .handleAction(A.beginLogin, state => ({
    ...state,
    processingLogIn: true,
  }))

  .handleAction(A.loginSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    fetched: true,
    loggedIn: true,
    processingLogIn: false,
  }))

  .handleAction(A.loginFailure, state => ({
    ...state,
    fetched: true, // fetching itself is done.
    loggedIn: false,
    processingLogIn: false,
  }))

  .handleAction(A.logoutSuccess, () => ({
    ...initialState,
    fetched: true,
    loggedIn: false,
  }));
