import React from 'react';

const registry: {
  [name: string]: React.FC<any> | undefined;
} = {};

export const register = (name: string, c: React.FC<any>) => {
  registry[name] = c;
};

export const get = (name: string) => registry[name];
