import React from 'react';
import { Create as AdminCreate, SimpleForm, SelectInput, TextInput, NumberInput, required } from 'react-admin';
import { LangCode } from 'sake-st-api';

type Props = {
  [adminProps: string]: any;
};

// TODO: support translation

export const Create: React.FC<Props> = props => {
  return <AdminCreate {...props}>
    <SimpleForm>
      <SelectInput source="lang" choices={[
        { id: LangCode.ja, name: "日本語" },
        { id: LangCode.en, name: "英語" },
      ]} initialValue={LangCode.ja} validate={[required()]} />
      <TextInput source="name" fullWidth validate={[required()]} />
      <NumberInput source="sort_order" min={1} max={1000} validate={[required()]} />
      <TextInput source="image_url" fullWidth placeholder="https://images.sakestreet.com/..." validate={[required()]} />
      <TextInput source="website" fullWidth placeholder="https://www..." />
      <TextInput source="twitter" fullWidth placeholder="https://twitter.com/..." />
      <TextInput source="facebook" fullWidth placeholder="https://facebook.com/..." />
      <TextInput source="instagram" fullWidth placeholder="https://instagram.com/..." />
      <TextInput source="title" fullWidth placeholder="唎酒師, etc." />
      <TextInput source="description" multiline fullWidth validate={[required()]} />
    </SimpleForm>
  </AdminCreate>;
};
