import React from 'react';
import {
  Create as AdminCreate, SimpleForm, TextInput, required,
  SelectInput, CreateProps,
  useQuery, Error, Loading,
} from 'react-admin';
import { useLocation } from 'react-router';
import { LangCode } from 'sake-st-api';

export const Create: React.FC<CreateProps> = props => {
  const q = new URLSearchParams(useLocation().search);
  const from = q.get('from');
  const to = q.get('to');
  const id = q.get('id');

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "writers",
    payload: { id: `${from}:${id}` },
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <div>執筆者情報を取得できませんでした</div>;

  return <AdminCreate {...props}>
    <SimpleForm sanitizeEmptyValues={false} initialValues={{
      ...data,
      id,
      from,
      to,
    }}>
      <SelectInput source="from" disabled={true} choices={[
        { id: LangCode.ja, name: "日本語" },
        { id: LangCode.en, name: "英語" },
      ]} validate={[required()]} />
      <SelectInput source="to" disabled={true} choices={[
        { id: LangCode.ja, name: "日本語" },
        { id: LangCode.en, name: "英語" },
      ]} validate={[required()]} />

      <TextInput source="id" disabled={true} validate={[required()]} />
      <TextInput source="name" fullWidth validate={[required()]} />
      <TextInput source="image_url" disabled fullWidth placeholder="https://images.sakestreet.com/..." validate={[required()]} />
      <TextInput source="website" disabled fullWidth placeholder="https://www..." />
      <TextInput source="twitter" disabled fullWidth placeholder="https://twitter.com/..." />
      <TextInput source="facebook" disabled fullWidth placeholder="https://facebook.com/..." />
      <TextInput source="instagram" disabled fullWidth placeholder="https://instagram.com/..." />
      <TextInput source="title" fullWidth placeholder="唎酒師, etc." />
      <TextInput source="description" multiline fullWidth validate={[required()]} />
    </SimpleForm>
  </AdminCreate>;
};
