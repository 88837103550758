import React from 'react';
import { createUseStyles } from 'react-jss';
import { NodeType, Heading } from 'sake-st-markdown-types';
import { Context } from '../context';
import { register } from './registry';
import { Children } from '../markdown';

class iterator {
  private i: number = 0;
  constructor(readonly a: Heading[]) {
    this.a = a;
  }
  done() { return this.i >= this.a.length; }
  peek() { return this.done() ? undefined : this.a[this.i]; }
  next() {
    // Because this.done() ? undefined : this.a[this.i++] cannot fully be
    // covered, this code intentionally removed the check as it doesn't change
    // the behavior.
    return this.a[this.i++];
  }
};

type HeadingNode = Heading & {
  children?: HeadingNode[];
};

const buildTree = (it: iterator, curLevel: number): HeadingNode[] | Error => {
  let res: HeadingNode[] = [];
  while (true) {
    const heading = it.peek();
    if (!heading) break;
    if (heading.level < curLevel) break; // next sibling or parent
    if (heading.level === curLevel) {
      res.push(heading);
      it.next();
      continue;
    }

    // visit children
    if (res.length === 0) {
      // This happens, for example, when headings are like
      //
      //  # h1
      //  ### h3 (h2 was skipped)
      return Error('skipped some levels of headings');
    }
    const childrenOrError = buildTree(it, curLevel + 1);
    if (childrenOrError instanceof Error) {
      return childrenOrError;
    }
    res[res.length - 1].children = childrenOrError;
  }
  return res;
};

type TreeProps = {
  context: Context;
  tree: HeadingNode[];
};

const Tree: React.FC<TreeProps> = ({ context, tree }) => (
  <ol className="tocOl">
    {
      tree.map((e, i) => (
        <li key={i}>
          <a href={`#${e.id}`}>
            <Children context={context} nodes={e.content} />
          </a>
          {e.children && <Tree context={context} tree={e.children} />}
        </li>
      ))
    }
  </ol>
);

type Props = {
  context: Context;
  minLevel?: number;
  maxLevel?: number;
};

const useStyles = createUseStyles({
  toc: {
    marginTop: 40,
    padding: 30,
    width: '100%',
    borderRadius: 8,
    backgroundColor: '#fff',
    display: 'flex',

    // TODO: replace @media with macros
    '@media (max-width: 480px)': {
      flexWrap: 'wrap',
    },

    '& div': {
      paddingRight: 30,
      fontSize: 18,
      fontFamily: ['Crimson Text', 'sans-serif'],
      wordWrap: 'normal',
      wordBreak: 'normal',
      '@media (max-width: 480px)': {
        width: '100%',
      },
    },

    '& ol.tocOl': {
      margin: 0,
      borderLeft: [1, 'solid', '#333'],
      paddingLeft: 44,
      '@media (max-width: 480px)': {
        paddingLeft: 30,
      },

      '& li a': {

      },

      '& ol.tocOl': {
        borderLeft: 0,
        paddingLeft: 20,
        listStyleType: 'lower-roman',
        margin: 0,
      },
    },
  },
});

const ToC: React.FC<Props> = props => {
  const styles = useStyles();
  const c = props.context;
  // Because # is used for the title, contents usually start from ##.
  const minLevel = props.minLevel ? props.minLevel : 2;
  const maxLevel = props.maxLevel ? props.maxLevel : 3;
  const headings = c.root.content && c.root.content
    .filter(e => e.type === NodeType.Heading && minLevel <= e.level && e.level <= maxLevel)
    .map(e => e as Heading);
  if (!headings || headings.length === 0) {
    if (c.debug) {
      return <span className="error">no headings found</span>;
    }
    return null;
  }

  const tree = buildTree(new iterator(headings), minLevel);
  if (tree instanceof Error) {
    if (c.debug) {
      return <span className="error">cannot build a ToC tree: {tree.toString()}</span>
    }
    return null;
  }
  return <div className={styles.toc}>
    <div>Index</div>
    <Tree context={c} tree={tree} />
  </div>;
};

export default ToC;
register('toc', ToC);
