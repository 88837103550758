import React, { useCallback, useState } from 'react';
import { useInput, useDataProvider, InputProps } from 'react-admin';
import ReactMde, { SaveImageHandler } from 'react-mde';

import { MediaAPI, LangCode, MediaImages } from 'sake-st-api';
import { Markdown, newContext } from 'sake-st-markdown';
import { ContainerWrapper, ContainerFirst, ContainerMain, ContainerLeft } from 'sake-st-containers';

// Don't load preview
import "react-mde/lib/styles/css/react-mde-editor.css";
import "react-mde/lib/styles/css/react-mde-suggestions.css";
import "react-mde/lib/styles/css/react-mde-toolbar.css";
import "react-mde/lib/styles/css/react-mde.css";

type EditorProps = InputProps & {
  mediaTitle?: string,
  lang: LangCode,
};

// TODO: fix images left margin or padding

// Note: It seems like ReactMde doesn't update paste.saveImage and the first
// instance of the saveImage is always used even if the captured values are
// updated. So, assuming there's only one editor at a time, this global variable
// is used to reflect the mediaTitle props to the saveImage closure.
let globalDangerousMediaTitle = '';

export const Editor: React.FC<EditorProps> = props => {
  const {
    input: { value, onChange },
    // TODO: report error in a mui way
  } = useInput(props);
  globalDangerousMediaTitle = props.mediaTitle || '';
  const [tab, setTab] = useState<'write' | 'preview'>('write');

  const preview = useCallback((content: string) => {
    // TODO: specify the correct lang code
    return MediaAPI.preview(content).then(body =>
      <ContainerWrapper>
        <ContainerFirst>
          <ContainerMain>
            <ContainerLeft>
              <div style={{
                margin: '0 auto',
                width: '630px',
              }}>
                <div style={{ clear: 'both' }} />
                <Markdown context={newContext(body, props.lang, { debug: true })} node={body} />
              </div>
            </ContainerLeft>
          </ContainerMain>
        </ContainerFirst>
      </ContainerWrapper>)
  }, []);

  const dataProvider = useDataProvider();
  const saveImage: SaveImageHandler = async function* (data) {
    try {
      const res = await dataProvider.create('media-images', {
        data: {
          image: data,
          comment: globalDangerousMediaTitle,
          copyright: '',
        },
      });
      const img = res.data as MediaImages.Record;
      yield img.url;
      return true;

    } catch (e) {
      yield "アップロードできませんでした";
      return false;
    }
  };

  return <ReactMde
    value={value}
    selectedTab={tab}
    onTabChange={setTab}
    onChange={onChange}
    generateMarkdownPreview={preview}
    minEditorHeight={800}
    maxEditorHeight={4000}
    paste={{ saveImage }}
  />;
};
