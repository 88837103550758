import { ErrorResponse, APIError, HTTPError, ExceptionError } from '../error';
import { LangCode } from 'lang';
import 'isomorphic-unfetch';
import { Headers as NodeHeaders } from 'node-fetch';

// TODO: fix this in a better way (probably by with axios)
globalThis.Headers = globalThis.Headers || NodeHeaders;

/**
 * Options for {@link setAPIConfig}.
 */
export type APIConfigOptions = {
  /**
   * True if the request is sent to the same origin, otherwise false.
   */
  readonly sameOrigin?: boolean,
};

/**
 * Configuration for {@link invoke}.
 */
export type APIConfig = {
  /**
   * The url of the API server that requests will be sent. It doesn't have a
   * trailing slash.
   */
  readonly url: string,

  /**
   * True if the request is sent to the same origin, otherwise false.
   */
  readonly sameOrigin: boolean,
};

let config: APIConfig | undefined;

/**
 * Configure {@link invoke} to make API calls.
 *
 * @param url The URL of the API server. A trailing slash is optional.
 * @param opt Options.
 */
export const setAPIConfig = (url: string, opt?: APIConfigOptions) => {
  config = {
    url: url.slice(-1) !== '/' ? url : url.slice(0, -1),
    sameOrigin: !!opt?.sameOrigin,
  };
};

/**
 * Get the current configuration of {@link invoke}.
 */
export const getAPIConfig = (): APIConfig | undefined => (config && { ...config });

const isJSONResponse = (r: Response) => {
  const ct = r.headers.get('Content-Type');
  return ct?.startsWith('application/json');
};

/**
 * When a response from the API server is a JSON, the return value of
 * {@link invoke} has this type.
 */
export type JSONResponse = {
  kind: 'json',
  json: any,
};

/**
 * When a response from the API server isn't a JSON, the return value of
 * {@link invoke} has this type.
 */
export type HTTPResponse = {
  kind: 'http',
  response: Response,
};

export type InvokeResponse = JSONResponse | HTTPResponse;
export type Method = 'GET' | 'POST' | 'HEAD' | 'PATCH' | 'OPTIONS' | 'PUT' | 'DELETE';
// TODO: provide possible error types after defining a common error format of the API server.

export type InvokeOptions = {
  csrfToken?: string,
  query?: URLSearchParams,
  lang?: LangCode,
};

/**
 * Make an API call to the API server configured by {@link setAPIConfig}.
 *
 * @param path The path of an endpoint starting with /.
 * @param method HTTP Method.
 * @param options Options for fetch API.
 * @param body JSON request body.
 *
 * When sending non-JSON request, set body field of options and don't use the
 * body argument of the function.
 *
 * When error happens, this function returns a Promise.reject of
 * {@link InvokeError}.
 *
 * This function doesn't proactively refresh the access token and it's caller's
 * responsibility to keep it fresh. This is because session management is
 * strongly tied to the user interface and the logic should be written in one
 * place to manage the token in a consistent way with the login flow.
 */
export const invoke = async (path: string, method?: Method, invokeOptions?: InvokeOptions,
  body?: object, options?: RequestInit): Promise<InvokeResponse> => {
  try {
    if (!config) {
      throw new Error("the hostname of the API hasn't been set yet");
    }
    options = options || {};
    const headers = new Headers(options.headers);

    if (body) {
      headers.set('Content-Type', 'application/json');
      options.body = JSON.stringify(body);
    }

    if (invokeOptions) {
      if (invokeOptions.csrfToken) {
        headers.set('X-CSRF-Token', invokeOptions.csrfToken);
      }

      if (invokeOptions.lang) {
        invokeOptions.query = invokeOptions.query || new URLSearchParams();
        invokeOptions.query.append('lang', invokeOptions.lang);
      }
    }
    options.method = method;
    options.headers = headers;
    options.credentials = options.credentials || (config.sameOrigin ? 'same-origin' : 'include');

    let q = '';
    if (invokeOptions?.query) {
      q = `?${invokeOptions.query.toString()}`;
      if (q === '?') q = '';
    }
    const response = await fetch(config.url + path + q, options);
    let result: InvokeResponse = {
      kind: 'http',
      response,
    };
    if (isJSONResponse(response)) {
      result = {
        kind: 'json',
        json: await response.json(),
      };
    }

    if (response.status >= 400) {
      let jsonRes: object | undefined = undefined;
      if (result.kind === 'json') {
        const error: ErrorResponse | undefined = result.json;
        if (error && error.code && error.request_id && error.message && error.status) {
          const res: APIError = {
            errorKind: 'api',
            error,
          };
          throw res;
        }
        jsonRes = result.json;
        // Unexpected JSON format will be fall-though as an HTTPError.
      }
      const res: HTTPError = {
        errorKind: 'http',
        response: response,
        json: jsonRes,
      };
      throw res;
    }

    return result;
  } catch (err) {
    if (err.errorKind && (err.errorKind === 'api' || err.errorKind === 'http')) {
      throw err;
    }
    const res: ExceptionError = {
      errorKind: 'exception',
      error: err,
    };
    throw res;
  }
};

/**
 * Obtain the return value of {@link invoke} as a JSON.
 *
 * @param r Response from {@link invoke}
 */
export const asJSON = (r: InvokeResponse) => {
  if (r.kind !== 'json') throw new Error('response was not JSON');
  return r.json;
};

/**
 * Obtain the return value of {@link invoke} as a regular HTTP Response.
 *
 * @param r Response from {@link invoke}
 */
export const asResponse = (r: InvokeResponse) => {
  if (r.kind !== 'http') throw new Error('response was not http Response');
  return r.response;
};

/**
 * encodes returns URI-encoded value. It behaves same as encodeURIComponent
 * but also encodes !'()*.
 *
 * @param str a string value to be encoded.
 */
export const encode = (str: string) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => (
    '%' + c.charCodeAt(0).toString(16)
  ));
};
