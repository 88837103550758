import { InvokeError } from 'sake-st-api';

export type State = {
  readonly errors: {
    [scope: string]: InvokeError[];
  };
  readonly maxHistory: number;
};

export const ValidationErrorCode = 3;
