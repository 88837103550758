import { Node } from 'sake-st-markdown-types';
import { LangCode } from 'sake-st-api';

export type ContextOptions = Partial<{
  debug: boolean,
  onRelatedArticles: (slugs: string[]) => void,
  onRelatedProductIds: (ids: string[]) => void,
}>;

export type Context = {
  root: Node,
  lang: LangCode,
  tight: boolean,
} & ContextOptions;


export const newContext = (root: Node, lang: LangCode, options?: ContextOptions): Context => ({
  root,
  lang,
  tight: false,
  ...options,
});
