import React from 'react';

export type IdFieldProps = {
  source: string,
  record?: { id: string },
  addLabel?: boolean,
};

/**
 * IdField is a React component to present an ID value that contains LangCode
 * as its prefix (e.g. 'ja-JP:123456789).
 */
export const IdField: React.FC<IdFieldProps> = props => {
  return <span>
    {props.record?.id.split(':')[1]}
  </span>;
};

IdField.defaultProps = {
  addLabel: true,
};
