import React from 'react';
import {
  Create as AdminCreate, SimpleForm, TextInput,
  ReferenceInput, SelectInput, ReferenceArrayInput,
  SelectArrayInput, required, FormDataConsumer, DateInput,
} from 'react-admin';
import { LangCode } from 'sake-st-api';
import { DebounceImage } from '../common/debounce-image';
import { Editor } from './editor';

export type CreateProps = {
  [adminProps: string]: any,
};

const requiredValidations = [required()];

export const Create: React.FC<CreateProps> = props => {
  return <AdminCreate {...props}>
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput source="title" fullWidth={true} validate={requiredValidations} />
      <ReferenceInput source="author" reference="writers" validate={requiredValidations}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="slug" fullWidth={true} validate={requiredValidations} />
      <TextInput source="summary" fullWidth={true} validate={requiredValidations} />
      <DateInput source="published_at" validate={requiredValidations} />
      <TextInput source="image_url" fullWidth={true} validate={requiredValidations} />
      <FormDataConsumer>
        {
          ({ formData }) => <DebounceImage src={formData.image_url} />
        }
      </FormDataConsumer>
      <ReferenceArrayInput source="tags" label="タグ" reference="media-tags">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <SelectInput source="lang" choices={[
        { id: LangCode.ja, name: "日本語" },
        { id: LangCode.en, name: "英語" },
      ]} initialValue={LangCode.ja} validate={requiredValidations} />
      <FormDataConsumer>
        {
          ({ formData }) => <Editor source="raw_content" mediaTitle={formData.title} lang={formData.lang as LangCode} validate={requiredValidations} />
        }
      </FormDataConsumer>
    </SimpleForm>
  </AdminCreate>;
};
