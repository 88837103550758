import { DataProvider } from 'react-admin';
import { invoke, MediaAPI } from 'sake-st-api';

const getList: DataProvider['getList'] = async (_, params) => {
  const query = new URLSearchParams();
  query.append('page', (params.pagination.page - 1).toString());
  query.append('size', params.pagination.perPage.toString());
  query.append('sort', `${params.sort.field}:${params.sort.order === 'ASC' ? 'asc' : 'desc'}`);

  query.set('from', params.filter.from);
  query.set('to', params.filter.to);
  query.set('translated', params.filter.translated ? '1' : '0');

  query.set('lang', params.filter.from);
  if ('q' in params.filter) query.set('q', params.filter.q);

  const res = await invoke("/v1/media/translations", "GET", {
    query
  });
  if (res.kind !== 'json') {
    throw new Error("unexpected HTTP response");
  }
  return {
    data: res.json.media.map((m: MediaAPI.Media) => {
      const author = m.author ? `${m.lang}:${m.author.id}` : undefined;
      return {
        ...m,
        author,
      }
    }),
    total: res.json.paging.total,
  };
};

const create: DataProvider['create'] = async (_, params) => {
  const { data } = params;
  const token = await MediaAPI.new(data.from);
  const res = await invoke(`/v1/media/${data.slug}/translations`, 'POST', {
    csrfToken: token.csrfToken,
  }, {
    title: data.title,
    // author will be ignored
    slug: data.slug,
    summary: data.summary,
    image_url: data.image_url,
    content: data.raw_content,
    published_at: data.published_at + 'T12:00:00Z',
    lang: data.to,
  });
  if (res.kind !== 'json') {
    throw new Error("unexpected HTTP response");
  }

  return {
    data: res.json.media,
  }
}

export const MediaTranslationsDataProvider: DataProvider = {
  getList,
  create,
} as any;
