export const baseBackgroundColor = '#ddd9d3';
export const firstContainerBackgroundColor = '#f5f2ed';

export const baseFontColor = '#333';
export const subFontColor = '#968f86';
export const baseLinkHoverColor = '#98001b';
export const baseLetterSpacing = '0.02em';

export const enMainFontFamily = ['Crimson Text', 'Noto Serif JP', 'sans-serif'];
export const enSubFontFamily = ['Roboto', 'Yu Gothic Medium', '游ゴシック Medium',
  'YuGothic', '游ゴシック体', 'sans-serif'];

export const widthComputer = 1140;
export const widthTablet = 960;
export const widthSmartphone = 480;

/**
 * One of the styles for hover. Use the spread operator ... to include
 * properties.
 */
export const hoverWithUnderline = {
  color: baseLinkHoverColor,
  textDecoration: 'underline',
} as const;

export const hoverWithoutUnderline = {
  color: baseLinkHoverColor,
  textDecoration: 'none',
} as const;

export const clearfix = {
  clear: 'both',
} as const;

export const backgroundImageURL = 'https://images.sakestreet.com/m/65179700224/3766259384151555229.gif';
export const smartphoneBackgroundImageURL = 'https://images.sakestreet.com/m/65179824128/6644681831997278554.gif';

export const logoImageURL = 'https://images.sakestreet.com/m/65208740864/14707042374418449988.png';
export const smartphoneLogoImageURL = 'https://images.sakestreet.com/m/65208776704/15744517142090008934.png';
export const footerLogoImageURL = 'https://images.sakestreet.com/m/65205670912/7282682108421044077.png';

export const twitterLogoOffImageURL = 'https://images.sakestreet.com/m/65268092928/4255370182191807654.png';
export const facebookLogoOffImageURL = 'https://images.sakestreet.com/m/65268111360/1369212616483896557.png';
export const instagramLogoOffImageURL = 'https://images.sakestreet.com/m/65255994368/17071892984854501420.png';

export const websiteIconOffImageURL = 'https://images.sakestreet.com/m/65351305216/2489832795646991877.gif';
export const twitterIconOffImageURL = 'https://images.sakestreet.com/m/65255596032/1696940713609600393.gif';
export const facebookIconOffImageURL = 'https://images.sakestreet.com/m/65255922688/17939384215130606004.gif';
