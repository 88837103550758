import React from 'react';
import { Edit as AdminEdit, SimpleForm, ImageField, TextInput, TextField, DateField } from 'react-admin';

export type EditProps = {
  [adminProps: string]: any;
};

// TODO: add button to copy Url to the clipboard
export const Edit: React.FC<EditProps> = props => {
  return <AdminEdit {...props} undoable={false}>
    <SimpleForm>
      <ImageField source="url" label="Image" />
      <TextField source="url" />
      <TextInput multiline source="comment" fullWidth={true} />
      <TextInput multiline source="copyright" fullWidth={true} />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleForm>
  </AdminEdit>;
};
