import React from 'react';
import {
  List as AdminList, ListProps, Datagrid, TextField, DateField,
} from 'react-admin';
import { TranslationButton, translationFilterDefaults, translationFilters } from '../common';

export const List: React.FC<ListProps> = props => {
  return <AdminList {...props} filters={translationFilters} perPage={100}
    hasCreate={false} bulkActionButtons={false} filterDefaultValues={translationFilterDefaults}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="created_at" />
      <TranslationButton resource="media-tag-translations" />
    </Datagrid>
  </AdminList >
};
