import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { Admin, DashboardMenuItem, getResources, Layout, LayoutProps, MenuItemLink, MenuProps, Resource, RouteWithoutLayout } from 'react-admin';
import { Provider } from 'react-redux';
import {
  ExtensionArticle, register,
  ExtensionArticleLinkProps, RelatedArticles,
} from 'sake-st-markdown';
import { createAdminStore } from './createAdminStore';
import { dataProvider } from './data-providers';
import { fetchMe } from './redux/session';
import { useSelector } from './redux';
import * as Media from './components/media';
import * as MediaWriters from './components/media-writers';
import * as MediaImages from './components/media-images';
import * as MediaTags from './components/media-tags';
import * as MediaTranslations from './components/translations/media';
import * as MediaWriterTranslations from './components/translations/media-writers';
import * as MediaTagTranslations from './components/translations/media-tags';
import { ShippingOrders } from './components/orders/shipping';
import { PickUpOrders } from './components/orders/pickup';
import { useMediaQuery, Theme } from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';
import LabelIcon from '@material-ui/icons/Label';

const Link: React.FC<ExtensionArticleLinkProps> = props => {
  return <a href={`https://sakestreet.com/${props.lang.split('-')[0]}/media/${props.slug}`}
    target="_blank" rel="noopener noreferrer" title={props.slug}>{props.children}</a>;
};
register('article', props => <ExtensionArticle {...props} Link={Link} />);
register('related-articles', props => <RelatedArticles {...props} Link={Link} />);

const history = createBrowserHistory();
const { store, authProvider } = createAdminStore(dataProvider, history);

const customRoutes = [
  <RouteWithoutLayout exact noLayout path="/orders/shipping" component={ShippingOrders} />,
  <RouteWithoutLayout exact noLayout path="/orders/pick-up" component={PickUpOrders} />
];

const App: React.FC = () => {
  const session = useSelector(state => state.session);
  useEffect(() => {
    store.dispatch(fetchMe());
  }, []);
  if (!session.fetched) {
    return <div>loading...</div>;
  }

  return <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    history={history}
    customRoutes={customRoutes}
    layout={CustomLayout}>
    <Resource name="media" options={{ label: '記事' }} list={Media.List} create={Media.Create} edit={Media.Edit} />
    <Resource name="writers" options={{ label: '執筆者' }} list={MediaWriters.List} create={MediaWriters.Create} edit={MediaWriters.Edit} />
    <Resource name="media-images" options={{ label: '画像' }} list={MediaImages.List} create={MediaImages.Create} edit={MediaImages.Edit} />
    <Resource name="media-tags" options={{ label: 'タグ' }} list={MediaTags.List} create={MediaTags.Create} edit={MediaTags.Edit} />
    <Resource name="media-translations" options={{ label: '記事翻訳' }} list={MediaTranslations.List} create={MediaTranslations.Create} />
    <Resource name="media-writer-translations" options={{ label: '執筆者翻訳' }} list={MediaWriterTranslations.List} create={MediaWriterTranslations.Create} />
    <Resource name="media-tag-translations" options={{ label: 'タグ翻訳' }} list={MediaTagTranslations.List} create={MediaTagTranslations.Create} />
  </Admin>;
};

const AppWrapper: React.FC = () => {
  return <Provider store={store}>
    <App />
  </Provider>;
};

export default AppWrapper;

const CustomLayout: React.FC<LayoutProps> = props => <Layout {...props} menu={Menu} />;

const Menu: React.FC<MenuProps> = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen); // TODO: remove any by adding react-admin's state to Redux
  const resources = useSelector(getResources);
  return (
    <div>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      {resources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
            resource.name
          }
          leftIcon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}

      <MenuItemLink
        to="/orders/shipping"
        primaryText="発送注文"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/orders/pick-up"
        primaryText="店舗受取り注文"
        leftIcon={<LabelIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  );
};
