import {
  widthComputer, widthTablet, widthSmartphone,
} from './constants';
import { CSSProperties } from './types';

/**
 * Generates a key of responsive styles for desktop/laptop computers.
 *
 * <code>
 * const jssStyle = {
 *   someClass: {
 *     display: 'block',
 *     ...mediaComputer({ // put ... to spread the media key.
 *       width: 100%,
 *     });
 *   }
 * }
 * </code>
 *
 * @param props CSS properties to be included.
 */
export const mediaComputer = (props: CSSProperties) => ({
  [`@media (max-width: ${widthComputer}px)`]: {
    ...props,
  },
});

/**
 * Generates a key of responsive styles for tablet devices.
 * @see {@link mediaComputer} for details.
 * @param props CSS properties to be included.
 */
export const mediaTablet = (props: CSSProperties) => ({
  [`@media (max-width: ${widthTablet}px)`]: {
    ...props,
  },
});

/**
 * Generates a key of responsive styles for smartphones.
 * @see {@link mediaComputer} for details.
 * @param props CSS properties to be included.
 */
export const mediaSmartphone = (props: CSSProperties) => ({
  [`@media (max-width: ${widthSmartphone}px)`]: {
    ...props,
  },
});
