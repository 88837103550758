import { DataProvider } from 'ra-core';
import { MediaWriters, LangCode, MediaWriter } from 'sake-st-api';

const modifyWriter = (w: MediaWriter) => {
  w.id = `${w.lang}:${w.id}`;
  return w;
};

const splitId = (id: string | number) => {
  const ids = id.toString().split(':');
  return {
    id: ids[1],
    lang: ids[0] as LangCode,
  }
};

const getList: DataProvider['getList'] = async (_, params) => {
  // TODO: should support paging?
  const writers = await MediaWriters.list(params.filter.lang);
  return {
    data: writers.map(w => {
      w.id = `${w.lang}:${w.id}`;
      return w;
    }) as any,
    total: writers.length,
  };
};

const getOne: DataProvider['getOne'] = async (_, params) => {
  const { id, lang } = splitId(params.id);
  const res = await MediaWriters.edit(id, lang);
  return {
    data: modifyWriter(res.writer) as any,
  };
};

const getMany: DataProvider['getMany'] = async (_, params) => {
  // TODO: provide endpoint to do this efficiently
  const res = await Promise.all(params.ids.map(jointId => {
    const { id, lang } = splitId(jointId);
    return MediaWriters.edit(id, lang);
  }));
  return {
    data: res.map(r => modifyWriter(r.writer)) as any,
  };
};

const getManyReference: DataProvider['getManyReference'] = async () => {
  // TODO: add ?author filter
  throw new Error("order has no reference");
};

const create: DataProvider['create'] = async (_, params) => {
  const token = await MediaWriters.new(params.data.lang);
  const writer = await MediaWriters.create(params.data.lang, params.data, token.csrfToken);
  return {
    data: modifyWriter(writer) as any,
  };
};

const update: DataProvider['update'] = async (_, params) => {
  const { id, lang } = splitId(params.id);
  const token = await MediaWriters.edit(id, lang);
  const writer = await MediaWriters.update(id, lang, params.data, token.csrfToken);
  return {
    data: writer as any,
  };
};

const updateMany: DataProvider['updateMany'] = async () => {
  throw new Error("update many isn't supported");
};

const deleteReq: DataProvider['delete'] = async (_, params) => {
  const { id, lang } = splitId(params.id);
  const token = await MediaWriters.edit(id, lang);
  await MediaWriters.delete(id, token.csrfToken);
  return {};
};

const deleteMany: DataProvider['deleteMany'] = async (_, params) => {
  const tokens = await Promise.all(params.ids.map(jointId => {
    const { id, lang } = splitId(jointId);
    return MediaWriters.edit(id, lang);
  }));
  await Promise.all(tokens.map(t => MediaWriters.delete(t.writer.id, t.csrfToken)));
  return {
    data: params.ids,
  };
};

export const MediaWriterDataProvider: DataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  updateMany,
  delete: deleteReq,
  deleteMany,
};