import React from 'react';
import { List as AdminList, Datagrid, TextField, ImageField } from 'react-admin';
import { TranslationButton, translationFilterDefaults, translationFilters } from '../common';

export type ListProps = {
  [adminProps: string]: any;
};

export const List: React.FC<ListProps> = props => {
  return <AdminList {...props} filters={translationFilters} filterDefaultValues={translationFilterDefaults}
    perPage={100} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <ImageField source="image_url" />
      <TextField source="sort_order" />
      <TextField source="created_at" />
      <TextField source="updated_at" />
      <TranslationButton resource="media-writer-translations" />
    </Datagrid>
  </AdminList>;
};
