import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  jss, mediaSmartphone, firstContainerBackgroundColor, useGlobalStyles,
  backgroundImageURL, smartphoneBackgroundImageURL
} from 'sake-st-styles';

const useStyles = createUseStyles({
  wrapper: jss({
    maxWidth: 1920,
    width: '100%',
    margin: [0, 'auto'],
    paddingTop: 77,
    backgroundColor: firstContainerBackgroundColor,
    backgroundImage: `url(${backgroundImageURL})`,
    backgroundPosition: ['center', 'top'],
    backgroundRepeat: 'no-repeat',
    ...mediaSmartphone({
      margin: 0,
      padding: 0,
      backgroundImage: `url(${smartphoneBackgroundImageURL})`,
      backgroundSize: [800, 'auto'],
      backgroundPosition: ['center', 'top'],
    }),
  }),
});

export const ContainerWrapper: React.FC = props => {
  useGlobalStyles(); // TODO: move this _app or in a higher order component
  const styles = useStyles();
  return <div className={styles.wrapper}>
    {props.children}
  </div>;
};
