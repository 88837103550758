import { createUseStyles } from 'react-jss';
import { jss } from './utils';
import {
  baseBackgroundColor,
  baseFontColor,
  enMainFontFamily,
  baseLetterSpacing,
} from './constants';
import { mediaSmartphone } from './responsive';

const globalStyles = {
  body: jss({
    display: 'block',
    backgroundColor: baseBackgroundColor,
    color: baseFontColor,
    fontFamily: enMainFontFamily,
    letterSpacing: baseLetterSpacing,
  }),

  'a:link': jss({
    color: baseFontColor,
    textDecoration: 'none',
  }),

  'a:visited': jss({
    color: baseFontColor,
    textDecoration: 'none',
  }),

  'a:hover': jss({
    color: baseFontColor,
    textDecoration: 'underline',
  }),

  'p.heading': jss({
    width: '100%',
    minWidth: 768,
    maxWidth: 1140,
    margin: [0, 'auto', 40],
    fontSize: 24,
    ...mediaSmartphone({
      marginBottom: 20,
      fontSize: 22,
      minWidth: '100%',
    }),
  }),

  // TODO: import div.container-xxx from base.scss. These doesn't have to be global.
  // 'div.container-wrapper': already moved to components/container/wrapper.tsx
};

export const useGlobalStyles = createUseStyles({
  '@global': globalStyles,
});
