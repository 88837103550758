import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { setAPIConfig } from 'sake-st-api';

// TODO: revert the <React.StrictMode> once https://github.com/marmelab/react-admin/issues/4655 is fixed

setAPIConfig(process.env.REACT_APP_API_URL || 'https://api.sakestreet.com');
ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
