import React from 'react';
import { Create as AdminCreate, SimpleForm, ImageInput, ImageField, TextInput, required } from 'react-admin';

export type CreateProps = {
  [adminProps: string]: any;
};

// TODO: Multiple file upload could be supported by using onSave with calling
// create in it multiple times.
// https://marmelab.com/react-admin/CreateEdit.html#using-onsave-to-alter-the-form-submission-behavior
//
// await Promise.all(data.map(d => ... call create ...));
// notify('ra.notification.created', 'info', { smart_count: n});
// redirect(... to the list page ...);

// TODO: ImageInput.accept doesn't receive string[]. react-dropzone supports
// string[], so it's react-admin's problem. Report it.

export const Create: React.FC<CreateProps> = props => {
  return <AdminCreate {...props}>
    <SimpleForm>
      <ImageInput source="image" accept="image/*" validate={[required()]}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput multiline source="comment" fullWidth={true} />
      <TextInput multiline source="copyright" fullWidth={true} />
    </SimpleForm>
  </AdminCreate>;
};
