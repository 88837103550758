import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { register } from './registry';

type Props = {
  id: string,
};

export const Tweet: React.FC<Props> = props => {
  // TODO: replace this widget with a simpler one.
  return <TwitterTweetEmbed tweetId={props.id} options={{ align: 'center' }} />;
};
register('tweet', Tweet);
