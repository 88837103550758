import React from 'react';
import { List as AdminList, Datagrid, TextField, ImageField, EditButton } from 'react-admin';
import { BulkDeleteToolbar } from '../common/list-toolbar';
import { langFilterDefaults, langFilters } from '../translations/common';

export type ListProps = {
  [adminProps: string]: any;
};

export const List: React.FC<ListProps> = props => {
  return <AdminList {...props} perPage={100} filters={langFilters} filterDefaultValues={langFilterDefaults}
    bulkActionButtons={<BulkDeleteToolbar confirmTitle="削除の確認" confirmContent="本当に削除しても良いですか？対象の全言語分が削除されます。" />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <ImageField source="image_url" />
      <TextField source="sort_order" />
      <TextField source="created_at" />
      <TextField source="updated_at" />
      <EditButton />
    </Datagrid>
  </AdminList>;
};
