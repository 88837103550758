import React from 'react';
import { List as AdminList, Datagrid, TextField, DateField, EditButton } from 'react-admin';
import { IdField } from '../common';
import { BulkDeleteToolbar } from '../common/list-toolbar';
import { langFilterDefaults, langFilters } from '../translations/common';

// TODO: support create and edit on List page
// https://marmelab.com/blog/2019/02/07/react-admin-advanced-recipes-creating-and-editing-a-record-from-the-list-page.html

export type ListProps = {
  [adminProps: string]: any,
};

export const List: React.FC<ListProps> = props => {
  return <AdminList {...props} filters={langFilters} filterDefaultValues={langFilterDefaults}
    bulkActionButtons={<BulkDeleteToolbar confirmTitle="削除の確認" confirmContent="本当に削除しても良いですか？対象タグの全言語分が削除されます。" />}
    perPage={100}>
    <Datagrid rowClick="edit">
      <IdField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="lang" />
      <DateField source="created_at" />
      <EditButton />
    </Datagrid>
  </AdminList>;
};
