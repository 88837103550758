import React from 'react';
import {
  Edit as AdminEdit, SimpleForm, TextInput, TextField, DateField,
  ReferenceInput, SelectInput, ReferenceArrayInput,
  SelectArrayInput, FormDataConsumer, DateInput, required, regex
} from 'react-admin';
import { LangCode } from 'sake-st-api';
import { DebounceImage } from '../common/debounce-image';
import { Editor } from './editor';

type Props = {
  [adminProps: string]: any;
};

// TODO: investigate why the Props below doesn't work.
// type Props = React.ComponentProps<typeof AdminEdit>;

const requiredValidations = [required()];

export const Edit: React.FC<Props> = props => {
  return <AdminEdit {...props} undoable={false} >
    <SimpleForm>
      <TextInput source="title" fullWidth={true} validate={requiredValidations} />
      <ReferenceInput source="author" reference="writers" validate={requiredValidations}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="slug" fullWidth={true} validate={[regex(/^[^\/]+$/, '/は使えません'), required()]} />
      <TextInput source="summary" fullWidth={true} validate={requiredValidations} />
      <DateInput source="published_at" validate={requiredValidations} />
      <TextInput source="image_url" fullWidth={true} validate={requiredValidations} />
      <FormDataConsumer>
        {
          ({ formData }) => <DebounceImage src={formData.image_url} />
        }
      </FormDataConsumer>
      <ReferenceArrayInput source="tags" label="タグ" reference="media-tags">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextField source="lang" />
      <SelectInput source="status" sortable={false} choices={[
        { id: 'draft', name: 'ドラフト' },
        { id: 'review', name: 'レビュー' },
        { id: 'published', name: '公開' },
      ]} validate={requiredValidations} />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <FormDataConsumer>
        {
          ({ formData }) => <Editor source="raw_content" mediaTitle={formData.title} lang={formData.lang as LangCode} validate={requiredValidations} />
        }
      </FormDataConsumer>
    </SimpleForm>
  </AdminEdit>;
};
