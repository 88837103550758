import { DataProvider } from 'ra-core';
import { MediaTags, LangCode } from 'sake-st-api';

const modifyMediaTag = (t: MediaTags.Record) => {
  t.id = `${t.lang}:${t.id}`;
  return t;
};

const splitId = (id: string | number) => {
  const ids = id.toString().split(':');
  return {
    id: ids[1],
    lang: ids[0] as LangCode,
  };
};

const getList: DataProvider['getList'] = async (_, params) => {
  const res = await MediaTags.list(params.filter.lang || LangCode.ja);
  return {
    data: res.media_tags.map(t => {
      t.id = `${t.lang}:${t.id}`;
      return t;
    }) as any,
    total: res.media_tags.length,
  };
};

const getOne: DataProvider['getOne'] = async (_, params) => {
  const { id, lang } = splitId(params.id);
  const res = await MediaTags.edit(id, lang);
  return {
    data: modifyMediaTag(res.media_tag) as any,
  };
};

const getMany: DataProvider['getMany'] = async (_, params) => {
  // TODO: provide endpoint to do this efficiently
  const res = await Promise.all(params.ids.map(jointId => {
    const { id, lang } = splitId(jointId);
    return MediaTags.edit(id, lang);
  }));
  return {
    data: res.map(r => modifyMediaTag(r.media_tag)) as any,
  };
};

const getManyReference: DataProvider['getManyReference'] = async () => {
  // TODO: add ?author filter
  throw new Error("order has no reference");
};

const create: DataProvider['create'] = async (_, params) => {
  const token = await MediaTags.new(params.data.lang);
  const tag = await MediaTags.create(params.data, token);
  return {
    data: modifyMediaTag(tag) as any,
  };
};

const update: DataProvider['update'] = async (_, params) => {
  const { id, lang } = splitId(params.id);
  const token = await MediaTags.edit(id, lang);
  const tag = await MediaTags.update(id, { ...params.data, lang }, token.csrf_token);
  return {
    data: tag as any,
  };
};

const updateMany: DataProvider['updateMany'] = async () => {
  throw new Error("update many isn't supported");
};

const deleteReq: DataProvider['delete'] = async (_, params) => {
  const { id, lang } = splitId(params.id);
  const token = await MediaTags.edit(id, lang);
  await MediaTags.delete(id, token.csrf_token);
  return {
    data: { id } as any,
  };
};

const deleteMany: DataProvider['deleteMany'] = async (_, params) => {
  const tokens = await Promise.all(params.ids.map(jointId => {
    const { id, lang } = splitId(jointId);
    return MediaTags.edit(id, lang);
  }));
  await Promise.all(tokens.map(t => MediaTags.delete(t.media_tag.id, t.csrf_token)));
  return {
    data: params.ids,
  };
};

export const MediaTagsDataProvider: DataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  create,
  update,
  updateMany,
  delete: deleteReq,
  deleteMany,
};