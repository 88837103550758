import { Node } from 'sake-st-markdown-types';
import { invoke, asJSON, encode } from '../invoke';
import { LangCode } from '../lang';
import {
  Media,
  MediaListArgTypes,
  MediaEditResponse,
  MediaRequestBody,
  MediaListResult,
  MediaShowResult,
  MediaShowOptions,
  MediaSitemapResult,
  MediaStatus,
} from './types';
import { Record } from '../media-tags';

export const list = async (lang: LangCode, args?: MediaListArgTypes): Promise<MediaListResult> => {
  return asJSON(await invoke(`/v1/media`, 'GET', { query: args?.query, lang }));
};

export const sitemap = async (lang: LangCode): Promise<MediaSitemapResult> => {
  return asJSON(await invoke('/v1/media/sitemap', 'GET', { lang }));
};

export const show = async (slug: string, lang: LangCode, opt?: MediaShowOptions): Promise<MediaShowResult> => {
  const query = new URLSearchParams();
  if (opt?.nav) query.append('nav', 't');
  if (opt?.withContent !== undefined) query.append('with_content', opt.withContent ? 't' : 'f');
  if (opt?.withRawContent !== undefined) query.append('with_raw', opt.withRawContent ? 't' : 'f');
  const res = asJSON(await invoke(slugPath(slug), 'GET', { lang, query }));
  return {
    media: res.media,
    next: res.next,
    prev: res.prev,
    latest: res.latest,
    trending: res.trending,
    popular: res.popular,
    pickup: res.pickup,
  };
};

const newMedia = async (lang: LangCode): Promise<{ csrfToken: string }> => { // TODO: define type
  return { csrfToken: asJSON(await invoke('/v1/media/new', 'GET', { lang })).csrf_token };
};;

export const create = async (lang: LangCode, body: MediaRequestBody, csrfToken: string): Promise<Media> => {
  const tags = requestBodyTags(body);
  return asJSON(await invoke('/v1/media', 'POST', { csrfToken }, { ...body, lang, tags })).media;
};

const requestBodyTags = (body: MediaRequestBody): string[] => {
  if (body.tags && body.tags.length > 0 && typeof body.tags[0] === 'object') {
    const t = body.tags as Record[]; // the type guard above doesn't work yet.
    return t.map(e => e.id);
  }
  return body.tags as string[];
};

export const edit = async (slug: string, lang: LangCode): Promise<MediaEditResponse> => {
  const res = asJSON(await invoke(`${slugPath(slug)}/edit`, 'GET', { lang }));
  return {
    media: res.media,
    csrfToken: res.csrf_token,
  };
};

const slugPath = (slug: string) => `/v1/media/${encode(slug)}`;

export const update = async (slug: string, lang: LangCode, body: MediaRequestBody,
  csrfToken: string): Promise<Media> => {
  const tags = requestBodyTags(body);
  return asJSON(await invoke(slugPath(slug), 'PUT', { csrfToken }, { ...body, lang, tags })).media;
};

export const updateStatus = async (slug: string, lang: LangCode, status: MediaStatus, csrfToken: string) => {
  await invoke(`${slugPath(slug)}/status`, 'PUT', { csrfToken }, { lang, status });
}

const deleteMedia = async (slug: string, lang: LangCode, csrfToken: string) => {
  await invoke(slugPath(slug), 'DELETE', { csrfToken }, { lang });
};

export const preview = async (content: string): Promise<Node> => {
  const res = await invoke('/v1/media/preview', 'POST', undefined, {
    content,
  });
  return asJSON(res);
}

export {
  newMedia as new,
  deleteMedia as delete,
};
