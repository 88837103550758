import React from 'react';
import { createUseStyles } from 'react-jss';
import { jss, mediaSmartphone } from 'sake-st-styles';

const useStyles = createUseStyles({
  first: jss({
    width: '100%',
    minWidth: 1090,
    maxWidth: 1160,
    margin: [0, 'auto'],
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...mediaSmartphone({
      margin: [0, 22],
      width: 'calc(100% - 44px)', //44px comes from the width of total margin
      minWidth: 0,
      position: 'relative',
    }),
  }),
});

export const ContainerFirst: React.FC = props => {
  const styles = useStyles();
  return <div className={styles.first}>
    {props.children}
  </div>;
};
