import React from 'react';
import { Edit as AdminEdit, SimpleForm, TextInput, TextField, DateField } from 'react-admin';
import { IdField } from '../common';
import { EditToolbar } from '../common/edit-toolbar';

export type EditProps = {
  [adminProps: string]: any;
};

export const Edit: React.FC<EditProps> = props => {
  return <AdminEdit {...props}>
    <SimpleForm toolbar={<EditToolbar confirmTitle="削除の確認" confirmContent="本当に削除しても良いですか？対象タグの全言語分が削除されます。" />}>
      <IdField source="id" />
      <TextInput source="name" fullWidth={true} />
      <TextInput source="description" fullWidth={true} />
      <TextField source="lang" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleForm>
  </AdminEdit>;
};
