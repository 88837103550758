import React, { useState } from 'react';
import {
  List as AdminList, Datagrid, TextField, ImageField,
  EditButton
} from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import copy from 'copy-to-clipboard';
import { MediaImages } from 'sake-st-api';
import { BulkDeleteToolbar } from '../common/list-toolbar';

export type ListProps = {
  [adminProps: string]: any;
};

export const List: React.FC<ListProps> = props => {
  return <AdminList {...props} bulkActionButtons={<BulkDeleteToolbar confirmTitle="削除の確認" confirmContent="本当に削除しても良いですか？" />}>
    <Datagrid>
      <ImageField source="url" sortable={false} />
      <URLCopyButton source="url" label="Copy url" sortable={false} />
      <TextField source="comment" sortable={false} />
      <TextField source="copyright" sortable={false} />
      <TextField source="created_at" sortable={false} />
      <EditButton />
    </Datagrid>
  </AdminList>;
};

type URLCopyButtonProps = {
  source: keyof MediaImages.Record,
  record?: MediaImages.Record,
  [adminProps: string]: any,
};

const URLCopyButton: React.FC<URLCopyButtonProps> = props => {
  const { source, record } = props;
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<React.MouseEvent['currentTarget'] | undefined>(undefined);

  if (!record) {
    return null;
  }
  const url = record[source];
  const handleClick = (event: React.MouseEvent) => {
    setAnchor(event.currentTarget);
    copy(url);
    setOpen(true);
  };

  return <span>
    <IconButton onClick={handleClick}>
      <FileCopyIcon />
    </IconButton>
    <Popover open={open} anchorEl={anchor} onClose={() => setOpen(false)}>
      Copied: {url}
    </Popover>
  </span>;
};