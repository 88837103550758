import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { StateType } from 'typesafe-actions';
import { AuthProvider } from 'ra-core';
import { History } from 'history';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {
  adminReducer,
  adminSaga,
  USER_LOGOUT,
} from 'react-admin';
import { State, ActionTypes, reducers, rootSaga } from './redux/root';
import { login, logout } from './redux/session';
import { InvokeError } from 'sake-st-api';

type promiseCallback = (resolve: () => void, reject: (error: InvokeError) => any) => void;
const promise = async (f: promiseCallback): Promise<void> => {
  return new Promise(f);
};

// TODO: remove any
export const createAdminStore = <H>(dataProvider: any, history: History<H>) => {
  const router = connectRouter(history);
  type AdminState = State & {
    admin: ReturnType<typeof adminReducer>,
    router: StateType<typeof router>,
  };
  const reducer = combineReducers<AdminState, ActionTypes>({
    admin: adminReducer,
    router: router as any, // TODO: type properly
    ...reducers,
  });
  const resettableAppReducer: typeof reducer = (state, action) =>
    reducer(action.type as any !== USER_LOGOUT ? state : undefined, action);

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;

  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    resettableAppReducer,
    undefined,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        // add your own middlewares here
      ),
      // add your own enhancers here
    ),
  );

  const authProvider: AuthProvider = {
    login: async (params: { username: string, password: string }) => {
      return promise((resolve, reject) => {
        store.dispatch(login({ email: params.username, password: params.password, resolve, reject }));
      });
    },

    logout: async () => {
      return promise((resolve, reject) => {
        store.dispatch(logout({ resolve, reject }));
      });
    },

    checkAuth: async () => {
      const me = store.getState().session;
      return me.loggedIn ? Promise.resolve() : Promise.reject();
    },

    checkError: async (error: InvokeError) => {
      if (error.errorKind !== 'api') return Promise.resolve();
      if (error.error.status === 401 || error.error.status === 403) return Promise.reject();
      return Promise.resolve();
    },

    getPermissions: async () => {
      const me = store.getState().session;
      if (!me.fetched) return Promise.reject();
      if (me.admin) return Promise.resolve('admin'); // TODO: define types
      if (me.media_writer) return Promise.resolve('editor'); // TODO: define types
      return Promise.reject();
    },
  };

  const saga = function* () {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        rootSaga,
      ].map(fork)
    );
  };

  sagaMiddleware.run(saga);
  return {
    store,
    authProvider,
  };
};
