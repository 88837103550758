import { Single } from './markdown';
import { Node as InternalNode } from 'sake-st-markdown-types';
import './extensions/register';
export { useStyles } from './markdown';
export { register } from './extensions/registry';
export { newContext } from './context';
export type { Context } from './context';
export {
  Article as ExtensionArticle,
} from './extensions/article';
export type {
  ArticleProps as ExtensionArticleProps,
  ArticleLinkProps as ExtensionArticleLinkProps,
} from './extensions/article';
export * from './extensions/related-articles';

export type Node = InternalNode;

export const Markdown = Single;
