export enum Kind {
  Normal = 'normal',
  Extension = 'extension',
  Invalid = 'invalid',
};

interface NormalElement {
  type: typeof Kind.Normal;
  content: string;
};

interface ExtensionElement {
  type: typeof Kind.Extension;
  name: string;
  props: {
    [key: string]: any;
  };
};

interface InvalidElement {
  type: typeof Kind.Invalid;
  content: string;
};

export type Element = NormalElement | ExtensionElement | InvalidElement;

const normal = (t: string): Element => ({
  type: Kind.Normal,
  content: t,
});

const extension = (name: string, props: { [key: string]: any; }): Element => ({
  type: Kind.Extension,
  name,
  props,
});

const invalid = (t: string): Element => ({
  type: Kind.Invalid,
  content: t,
});

export const parse = (t: string) => {
  let res: Element[] = [];
  let prev = 0;
  while (prev < t.length) {
    const i = t.indexOf('{<{', prev);
    if (i < 0) {
      res.push(normal(t.substr(prev)));
      break;
    }

    const sub = t.substr(prev, i - prev);
    if (sub.length !== 0) res.push(normal(sub));

    const end = t.indexOf('}>}', i + 3);
    if (end < 0) {
      res.push(invalid(`missing closing '}': ${t.substr(i)}`));
      break;
    }

    prev = end + 3;

    // extract JSOIN in {<{ }>} including inner '{}'.
    const ext = t.substr(i + 2, end - (i + 2) + 1);
    try {
      const j = JSON.parse(ext);
      if (j['type'] === undefined) {
        res.push(invalid(`the extension is missing "type": ${ext}`));
      } else {
        res.push(extension(j['type'], j));
      }
    } catch (e) {
      res.push(invalid(`cannot parse extension JSON: error = ${e}, data = ${ext}`));
    }
  }
  return res;
};

export default parse;
