import { createAction } from 'typesafe-actions';
import { InvokeError } from 'sake-st-api';

const PUT = 'error/PUT';
const CLEAR = 'error/CLEAR';
const CLEAR_ALL = 'error/CLEAR_ALL';

// put puts a new error to the given scope.
export const put = createAction(PUT,
  (scope: string, err: InvokeError | Error) => ({
    scope,
    err: 'errorKind' in err ? err : {
      errorKind: 'exception' as const,
      error: err,
    },
  }))();

// clear clears all the error information of the scope.
export const clear = createAction(CLEAR)<string>();

// clearAll clears error information of all scopes.
export const clearAll = createAction(CLEAR_ALL)();
