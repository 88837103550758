import React, { useEffect } from 'react';
import { Context } from '../context';
import { LangCode } from 'sake-st-api';
import { MarkdownError } from '../error';
import { Product } from './product';
import { register } from './registry';

export type ProductsProps = {
  context: Context,
  ids?: string[],
  lang?: LangCode,
};

export const Products: React.FC<ProductsProps> = props => {
  const { context, ids, lang } = props;
  useEffect(() => {
    if (context.onRelatedProductIds && ids && ids.length > 0) {
      context.onRelatedProductIds(ids);
    }
  }, [context, ids]);

  if (ids === undefined) {
    if (!context.debug) return null;
    return <MarkdownError>"slugs" が指定されていません。</MarkdownError>;
  }
  if (ids.length === 0) {
    return null;
  }
  if (context.onRelatedProductIds) {
    return null;
  }

  return <>
    <h2>関連商品</h2>
    {ids.map(id => <Product key={id} context={context} id={id} lang={lang} />)}
  </>;
};

register('products', Products);
register('relatedProducts', Products);
register('related-products', Products);
