import React from 'react';
import LazyLoad from 'react-lazyload';
import {
  NodeType,
  Node,
  Text,
  WrapperNode,
} from 'sake-st-markdown-types';
import { Context } from './context';
import { Extension } from './extensions';
import { parse, Kind } from './extensions/parse';
import { createUseStyles } from 'react-jss';
import { MarkdownError } from './error';
import { LangCode } from 'sake-st-api';

// TODO: extract constants and macros as a library.
const styleConstants = {
  widthPC: 1140,
  widthTable: 960,
  widthSP: 480,
  enMainFontFamily: ['Crimson Text', 'Noto Serif JP', 'sans-serif'] as string[],
  enSubFontFamily: ['Roboto', "Yu Gothic Medium", "游ゴシック Medium", 'YuGothic', "游ゴシック体", 'sans-serif'] as string[],
} as const;

const styleMacros = {
  pc: (content: object) => ({
    [`@media (max-width: ${styleConstants.widthPC}px)`]: {
      ...content,
    },
  }),
  sp: (content: object) => ({
    [`@media (max-width: ${styleConstants.widthSP}px)`]: {
      ...content,
    },
  }),
} as const;

export const useStyles = createUseStyles({
  mediaRoot: {
    width: '100%',
    marginTop: 40,
    fontSize: 16,
    fontFamily: styleConstants.enSubFontFamily as any,
    lineHeight: 2.0,
    clear: 'both',
    wordBreak: 'break-all',
    overflowWrap: 'break-word',

    '& a': {
      color: '#98001b',
    },
    '& p': {
      marginBottom: 24,
    },

    '& img.normalImg': {
      borderRadius: 8,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      ...styleMacros.sp({
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
      }),
    },

    '& h2': {
      margin: {
        top: 40,
        bottom: 24,
      },
      padding: {
        left: 10,
        bottom: 10,
      },
      borderBottom: [1, 'solid', '#333'],
      width: '100%',
      fontSize: 20,
      fontFamily: styleConstants.enMainFontFamily,
    },

    '& h2::before': {
      position: 'relative',
      top: 0,
      left: -10,
      width: 16,
      height: 16,
      content: '""',
      borderRadius: '100%',
      backgroundColor: '#98001b',
      display: 'inline-block',
    },

    '& h3': {
      margin: {
        top: 20,
        bottom: 10,
      },
      paddingLeft: 10,
      width: '100%',
      fontSize: 20,
      fontFamily: styleConstants.enMainFontFamily,
    },

    '& h3::before': {
      position: 'relative',
      top: -6,
      left: -10,
      width: 15,
      height: 1,
      content: '""',
      backgroundColor: '#98001b',
      display: 'inline-block',
    },

    '& table': {
      width: '100%',
      marginBottom: 20,
      borderCollapse: 'collapse',
      borderSpacing: 0,

      '& th': {
        backgroundColor: '#ebe7e1',
        padding: 10,
        fontWeight: 'bold',
        border: [1, 'solid', '#ddd8d2'],
      },

      '& td': {
        backgroundColor: '#fff',
        padding: 10,
        border: [1, 'solid', '#ddd8d2'],
      },
    },

    '& ul': {
      marginBottom: 24,

      '& li': {
        marginLeft: 18,
      },
    },

    '& ol': {
      marginBottom: 24,

      '& li': {
        marginLeft: 18,
      },
    },

    '& figure': {
      marginBottom: 24,
      textAlign: 'center',
      '& figcaption': {
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'italic',
      },
    },

    '& blockquote': {
      padding: [20, 20, 0, 20],
      marginBottom: 20,
      border: [1, 'solid', '#ddd8d2'],
      boxSizing: 'border-box',
      fontStyle: 'italic',
      color: '#464646',
      background: '#f5f5f5',
    },
  },

  mediaRootEn: {
    composes: '$mediaRoot',
    wordBreak: 'normal',
    overflowWrap: 'normal',
  },
});

type SingleProps = {
  context: Context,
  node: Node,
};

const SingleComponent: React.FC<SingleProps> = ({ context, node }) => {
  const styles = useStyles();
  const c = context;
  const n = node;
  const C: React.FC = () => <Children context={context} nodes={n.content} />;

  switch (n.type) {
    case NodeType.Document:
      return <div className={context.lang === LangCode.ja ? styles.mediaRoot : styles.mediaRootEn}><C /></div>;

    case NodeType.List:
      if (n.list_type !== 'ol' && n.list_type !== 'ul') {
        if (c.debug) {
          return <MarkdownError>unsupported list type &quot;{n.list_type}&quot;</MarkdownError>;
        }
        return null;
      }
      const ListElem = n.list_type as keyof React.ReactHTML;
      return <ListElem>
        <Children context={{ ...c, tight: n.tight }} nodes={n.content} />
      </ListElem>;

    case NodeType.Item:
      return <li><C /></li>;

    case NodeType.Paragraph: {
      const C: React.FC = () => <Children context={{ ...c, tight: false }} nodes={n.content} />;
      if (c.tight || containsFigure(n) || containsBlockExtension(n)) return <C />;
      return <p><C /></p>;
    }

    case NodeType.Blockquote:
      return <blockquote><C /></blockquote>;

    case NodeType.Heading: {
      const level = Math.max(1, Math.min(n.level, 6));
      const H = `h${level}` as keyof React.ReactHTML;
      return <>
        <a id={n.id} aria-label={`${n.id}`} className="anchor" href={`#${n.id}`}><span /></a>
        <H><C /></H>
      </>;
    }

    case NodeType.Emph:
      return <em><C /></em>;

    case NodeType.Strong:
      return <strong><C /></strong>;

    case NodeType.Del:
      return <span className="del"><C /></span>;

    case NodeType.Link:
      return <a href={n.href} title={n.title} target="_blank" rel="noopener"><C /></a>;

    case NodeType.Image: {
      // TODO: replace lazy-load with Next.js' image feature
      const tn = n.content && n.content[0];
      if (n.title && n.title !== '') {
        return <LazyLoad once height={400}>
          <figure>
            <img className="normalImg" src={n.href} alt={tn && tn.text} />
            <figcaption>{n.title}</figcaption>
          </figure>
        </LazyLoad>;
      }

      // We don't have an inline image in articles, so make this also a figure
      // for consistency.
      //
      // This might be going to get improved after resolving the issue below:
      // https://github.com/twobin/react-lazyload/issues/310
      return <LazyLoad once height={400}>
        <figure>
          <img className="normalImg" src={n.href} alt={tn && tn.text} />
        </figure>
      </LazyLoad>;
    }

    case NodeType.Text:
      return <TextComponent context={c} text={n} />;

    case NodeType.Hardbreak:
      return <br />;

    case NodeType.Table:
      return <table><C /></table>

    case NodeType.Tablecell: {
      if (n.header) {
        const style: { width?: string } = {};
        if (n.width) {
          style.width = `${n.width}%`;
        }
        return <th align={n.align} style={style}><C /></th>
      }
      return <td align={n.align}><C /></td>;
    }

    case NodeType.Tablehead:
      if (!n.content || !n.content[0]) {
        if (c.debug) {
          return <MarkdownError>tablehead must have one content</MarkdownError>;
        }
        return null;
      }
      return <thead>
        <SingleComponent context={c} node={n.content[0]} />
      </thead>;

    case NodeType.Tablebody:
      return <tbody><C /></tbody>;

    case NodeType.Tablerow:
      return <tr><C /></tr>;

    case NodeType.Extension:
      return <Extension context={c} name={n.data.type} extensionProps={n.data} content={<C />} />

    default:
      const _: never = n; _;
      if (c.debug) {
        const tmp = n as WrapperNode;
        return <MarkdownError>unknown node type &quot;{tmp.type}&quot;</MarkdownError>;
      }
      return null;
  }
};

// Only making the top level memoized should be fine because the content isn't
// typically updated. Also, the comparison of markdown props itself is pretty
// heavy and shouldn't be done at the lower levels.
export const Single = React.memo(SingleComponent);

type ChildrenProps = {
  context: Context,
  nodes?: Node[],
};

export const Children: React.FC<ChildrenProps> = ({ context, nodes }) => {
  if (!nodes) return null;
  // to avoid overhead, SingleComponent is directly used.
  return <>
    {nodes.map((n, i) => <SingleComponent key={i} context={context} node={n} />)}
  </>;
};

/**
 * containsFigure determins if the content of the node is figure.
 * @param p p node
 */
export const containsFigure = (p: Node) => {
  if (p.type !== NodeType.Paragraph) return false;
  if (!p.content) return false;
  if (p.content.length === 0) return false;

  // Becaues LazyLoad contains <div>, <p> must be removed not only for figures
  // but also for regular images.
  return p.content.findIndex(e => e.type === NodeType.Image) !== -1;
}

// A block extension is a paragraph that only has a single text content and
// it starts with '{<{'. In Markdown, it's written like:
//
// <empty line>
// {<{ ... }>}
// <empty line>
export const containsBlockExtension = (p: Node) => {
  if (!p.content) return false;
  if (p.content.length === 0) return false;
  if (p.content.length > 1) return false; // inline extensions only.

  const c = p.content[0];
  if (c.type !== NodeType.Text) return false;

  const t = (c as Text).text;
  return t.indexOf('{<{') === 0 && t.indexOf('}>}') === t.length - 3;
};

type TextComponentProps = {
  context: Context,
  text: Text,
};

export const TextComponent: React.FC<TextComponentProps> = props => {
  const c = props.context;
  const RawText: React.FC<{ text: string }> = ({ text }) => (<>{text}</>);

  return <>
    {parse(props.text.text).map((e, i) => {
      switch (e.type) {
        case Kind.Normal:
          return <RawText key={i} text={e.content} />;
        case Kind.Extension:
          return <Extension key={i} context={c} name={e.name} extensionProps={e.props} content={null} />;
        case Kind.Invalid:
          if (c.debug) return <MarkdownError key={i}>{e.content}</MarkdownError>;
          return null;
        default:
          const _: never = e; _;
          return null;
      }
    })}
  </>;
};
