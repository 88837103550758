import React from 'react';
import {
  List as AdminList, Datagrid, TextField, ImageField, SelectField, DateField,
  ReferenceField,
  ListProps,
  TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { TranslationButton, translationFilters, translationFilterDefaults } from '../common';

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: 100,
      width: 100,
      objectFit: "contain"
    }
  }
});

const filters = [
  ...translationFilters,
  <TextInput source="q" label="検索キーワード" />,
]

export const List: React.FC<ListProps> = props => {
  const styles = useStyles(props);
  return <AdminList {...props} sort={{ field: 'published_at', order: 'DESC' }} filters={filters}
    filterDefaultValues={translationFilterDefaults} bulkActionButtons={false}>
    <Datagrid>
      <ImageField source="image_url" label="メイン画像" className={styles.imgContainer} sortable={false} />
      <SelectField source="status" sortable={false} choices={[
        { id: 'draft', name: 'ドラフト' },
        { id: 'review', name: 'レビュー' },
        { id: 'published', name: '公開' },
      ]} />
      <TextField source="title" sortable={false} />
      <ReferenceField source="author" label="著者" reference="writers" sortable={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="published_at" sortable={true} />
      <DateField source="updated_at" sortable={true} />
      <TranslationButton resource="media-translations" idField="slug" />
    </Datagrid>
  </AdminList>;
};
