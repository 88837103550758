import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';

type Props = {
  src: string,
  width?: number,
  progressSize?: number,
};

export const DebounceImage: React.FC<Props> = props => {
  const { src, width = 250, progressSize = 125 } = props;
  const [url, setUrl] = useState(src);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setUrl(src);
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [src]);

  if (loading) {
    return <CircularProgress size={progressSize} />;
  }
  if (!src) {
    return null;
  }
  return <img src={url} width={width} alt="main image" />;
};
