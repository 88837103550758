import React from 'react';
import {
  Create as AdminCreate, SimpleForm, TextInput, required,
  SelectInput, CreateProps,
  useQuery, Error, Loading,
} from 'react-admin';
import { useLocation } from 'react-router';
import { LangCode } from 'sake-st-api';

export const Create: React.FC<CreateProps> = props => {
  const q = new URLSearchParams(useLocation().search);
  const from = q.get('from');
  const to = q.get('to');
  const id = q.get('id');

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "media-tags",
    payload: { id: `${from}:${id}` },
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <div>タグを取得できませんでした</div>;

  return <AdminCreate {...props}>
    <SimpleForm sanitizeEmptyValues={false} initialValues={{
      id,
      name: data.name,
      from,
      to,
    }}>
      <SelectInput source="from" disabled={true} choices={[
        { id: LangCode.ja, name: "日本語" },
        { id: LangCode.en, name: "英語" },
      ]} validate={[required()]} />
      <SelectInput source="to" disabled={true} choices={[
        { id: LangCode.ja, name: "日本語" },
        { id: LangCode.en, name: "英語" },
      ]} validate={[required()]} />

      <TextInput source="id" disabled={true} validate={[required()]} />
      <TextInput source="name" fullWidth={true} validate={[required()]} />
    </SimpleForm>
  </AdminCreate>;
};
