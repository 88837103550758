import React from 'react';
import {
  SelectInput,
} from 'react-admin';
import { LangCode } from 'sake-st-api';

export const translationFilters = [
  <SelectInput label="翻訳元言語" source="from" alwaysOn allowEmpty={false}
    choices={[
      { id: LangCode.ja, name: "日本語" },
      { id: LangCode.en, name: "英語" },
    ]} />,
  <SelectInput label="翻訳先言語" source="to" alwaysOn allowEmpty={false}
    choices={[
      { id: LangCode.ja, name: "日本語" },
      { id: LangCode.en, name: "英語" },
    ]} />,
  <SelectInput label="表示切り替え" source="translated" alwaysOn allowEmpty={false}
    choices={[
      { id: true, name: "翻訳済み" },
      { id: false, name: "未翻訳" },
    ]} />
];

export const translationFilterDefaults = {
  from: LangCode.ja,
  to: LangCode.en,
  translated: false,
};

export const langFilters = [
  <SelectInput label="言語" source="lang" alwaysOn allowEmpty={false}
    choices={[
      { id: LangCode.ja, name: "日本語" },
      { id: LangCode.en, name: "英語" },
    ]} />,
];

export const langFilterDefaults = {
  lang: LangCode.ja,
};
