import React from 'react';
import { Context } from '../context';
import { MarkdownError } from '../error';
import { get } from './registry';

interface Props {
  context: Context;
  name: string;
  extensionProps?: {
    [key: string]: any;
  };
  content: React.ReactNode,
};

export const Extension: React.FC<Props> = props => {
  const Ext = get(props.name);
  if (!Ext) {
    if (props.context.debug) {
      return <MarkdownError>
        undefined extension: name = ${props.name}, props=${JSON.stringify(props.extensionProps)}
      </MarkdownError>;
    }
    return null;
  }
  return <Ext {...props.extensionProps} context={props.context} content={props.content} />;
};
