import React from 'react';
import { createUseStyles } from 'react-jss';
import { mediaSmartphone, jss } from 'sake-st-styles';

const useStyles = createUseStyles({
  main: jss({
    width: 'calc(100% - 140px)',
    minWidth: 930,
    paddingTop: 10,
    display: 'flex',
    flexWrap: 'wrap',

    ...mediaSmartphone({
      //marginTop: 25,
      padding: 0,
      width: '100%',
      minWidth: 0,
      flexWrap: 'wrap',
    }),

  }),

  left: {
    minWidth: 650,
    width: '70%',
    ...mediaSmartphone({
      minWidth: '100%',
      width: '100%',
    }),
  },

  right: {
    margin: [0, 'auto'],
    minWidth: 280,
    width: 280,
    ...mediaSmartphone({
      marginTop: 22,
      minWidth: '100%',
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    }),
  },

  right2nd: {
    marginTop: 40,
    ...mediaSmartphone({
      margin: 0,
    }),
  },

  single: {
    width: '90%',
    minWidth: 880,
    margin: [0, 'auto', 100],
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    ...mediaSmartphone({
      margin: 0,
      paddingBottom: 50,
      minWidth: '100%',
      width: '100%',
    }),

    '&::after': {
      content: '""',
      display: 'block',
      width: 285,
      ...mediaSmartphone({
        width: 0,
      }),
    },
  },

  singleIndex: {
    marginTop: 50,
    ...mediaSmartphone({
      margin: 0,
    }),
  },
});

export const ContainerMain: React.FC = props => {
  const styles = useStyles();
  return <div className={styles.main}>{props.children}</div>;
};

export const ContainerLeft: React.FC = props => {
  const styles = useStyles();
  return <div className={styles.left}>{props.children}</div>;
};

export const ContainerRight: React.FC = props => {
  const styles = useStyles();
  return <div className={styles.right}>{props.children}</div>;
};

export const ContainerRight2nd: React.FC = props => {
  const styles = useStyles();
  return <div className={`${styles.right} ${styles.right2nd}`}>{props.children}</div>;
}

export const ContainerSingle: React.FC = props => {
  const styles = useStyles();
  return <div className={styles.single}>{props.children}</div>;
};

export const ContainerSingleIndex: React.FC = props => {
  const styles = useStyles();
  return <div className={`${styles.single} ${styles.singleIndex}`}>{props.children}</div>;
};