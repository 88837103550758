import * as A from './actions';
import { takeLatest, put, call, all, fork } from 'redux-saga/effects';
import * as ErrMod from '../error';
import { Session, User } from 'sake-st-api';

export const ErrorScope = "session";

export function* processFetchMe() {
  try {
    const user: User = yield call(Session.me);
    yield put(A.fetchMeSuccess(user));
  } catch (error) {
    // TODO: don't report error when it is about "login required".
    yield put(ErrMod.put(ErrorScope, error));
    yield put(A.fetchMeFailure());
  }
};

export function* watchFetchMe() {
  yield takeLatest(A.FETCH_ME, processFetchMe);
}

export function* processLogin(action: ReturnType<typeof A.login>) {
  const { email, password, resolve, reject } = action.payload;
  yield put(A.beginLogin());
  try {
    const response: User = yield call(Session.login, email, password);
    yield put(A.loginSuccess(response));
    yield call(resolve);
  } catch (error) {
    yield put(ErrMod.put(ErrorScope, error));
    yield put(A.loginFailure());
    yield call(reject, error);
  }
};

export function* watchLogin() {
  yield takeLatest(A.LOGIN, processLogin);
};

export function* processLogout(action: ReturnType<typeof A.logout>) {
  const { resolve, reject } = action.payload;
  try {
    yield call(Session.logout);
    yield put(A.logoutSuccess());
    yield call(resolve);
  } catch (error) {
    yield put(ErrMod.put(ErrorScope, error));
    yield call(reject, error);
  }
}

export function* watchLogout() {
  yield takeLatest(A.LOGOUT, processLogout);
}

export function* rootSaga() {
  yield all([
    fork(watchFetchMe),
    fork(watchLogin),
    fork(watchLogout),
  ]);
};
