import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  mdError: {
    border: [10, 'solid', '#ff0000'],
    fontWeight: 'bold',
    fontSize: 32,
    display: 'block',
  },
});

export const MarkdownError: React.FC = props => {
  const styles = useStyles();
  return <>
    <br />
    <span className={styles.mdError}>{props.children}</span>
    <br />
  </>;
};
